import React, { useEffect, useRef, useState } from 'react';
import { SequencesNumbersScreen } from '../screens/sequences-numbers/ui/screens/SequencesNumbersScreen'

export default function NumberSequences() {
 
    return (
        <div>
           <SequencesNumbersScreen></SequencesNumbersScreen>
        </div>
    );
}  