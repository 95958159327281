import React, { useState, useEffect, useRef } from "react";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckIcon from "@mui/icons-material/Check";
import { Avatar, Timeline, Row, Col } from "antd";
import moment from "moment";
import imageStatus from "../../../../images/turbo1.png";
import { CarOutlined , CheckCircleTwoTone} from '@ant-design/icons';
import { Padding } from "@mui/icons-material";
import { Grid, Divider, TextField, IconButton, InputAdornment, Box, Card } from '@mui/material';

const OrdersCard = ({ dailsOrder, onSelectDetails }) => {
const [selectedDetails, setSelectedDetails] = useState(null);

    const handleDetailsClick = (dailsOrder) => {
        setSelectedDetails(dailsOrder);
        onSelectDetails(dailsOrder);
    };

    useEffect(() => {
        if (dailsOrder?.length > 0 && !selectedDetails) {
            if (selectedDetails !== dailsOrder[0]) {
                setSelectedDetails(dailsOrder[0]);
                onSelectDetails(dailsOrder[0]);
            }
        }
    }, [dailsOrder, selectedDetails, onSelectDetails]);
      
      
    return (
        <div className="orders-card-container" >
            {dailsOrder?.length > 0 &&
                dailsOrder.map((dailsOrder, index) => (
                    <Box
                        key={index}
                        className="children-inner-container-target-with-bg-order-details"
                        style={{
                            border: selectedDetails === dailsOrder ? "2px solid var(--primaryColor)" : "2px solid transparent",
                        }}
                        onClick={() => handleDetailsClick(dailsOrder)}
                        
                    >
                        <Grid container spacing={1}>    
                            <Grid item xs={6}>
                                <span className="drive-details-label ord-titule">Orden</span><br/>
                                <span className="drive-details-value ord-value">{dailsOrder?.orderTable?.orderNumber}</span>
                            </Grid>
                            <Grid container item xs={6} justifyContent="flex-end">
                                <CheckIcon
                                    className={
                                        selectedDetails === dailsOrder
                                            ? "check-icon-container check-icon-container-selected"
                                            : "check-icon-container"
                                    }
                                    style={{ width: "35px", height: "35px" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <span className="drive-details-label carga-titule">Carga:</span><br/>
                                <span className="drive-details-value car-value">{dailsOrder?.orderTable?.consecutiveBurden}</span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}> 
                            <Grid item xs={2.5}>
                                <div className="drive-details-labels-continer">
                                    <span className="drive-details-label mini-titule">Tipo</span><br/>
                                    <span className="drive-details-value tipe-value">{dailsOrder?.orderTable?.type === "Envío" || dailsOrder?.orderTable?.type === "delivery"
                                                                                    ? "Envío": dailsOrder?.orderTable?.type === "pickup"? "Recogida": dailsOrder?.orderTable?.type === "customService"
                                                                                    ? "Serv Personalizado"
                                                                                    : dailsOrder?.orderTable?.type === "warehousesTransfer"
                                                                                    ? "Tras Bodegas"
                                                                                    : ""}
                                                                                    </span>
                                 </div>
                            </Grid>
                            <Grid item xs={2.5}>
                                <div className="drive-details-labels-continer">
                                    <span className="drive-details-label mini-titule">Estado</span><br/>
                                    <span className="drive-details-value status-value">{dailsOrder?.status== "none" || dailsOrder?.status === "start" ? "Ninguno" :
                                                                                    dailsOrder?.status === "started" ? "En reparto" :
                                                                                    dailsOrder?.status === "delivered" ? "Comple Entregado" :
                                                                                    dailsOrder?.status === "undelivered" ? "Devuelto" :
                                                                                    dailsOrder?.status === "partial_delivered" ? "Con Novedad" :
                                                                                    dailsOrder?.status === "rescheduled_delivery" ? "Entrega Reprogramada" :
                                                                                    "Ninguno"}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3.5}>
                                <div className="drive-details-labels-continer">
                                    <span className="drive-details-label mini-titule">OV/TRN</span><br/>
                                    <span className="drive-details-value ovtrn-value">{dailsOrder?.orderTable?.consecutiveSaleOrder}</span>
                                </div>
                            </Grid>
                            <Grid item xs={3.5}>
                                <div className="drive-details-labels-continer">
                                    <span className="drive-details-label mini-titule">Envío</span><br/>
                                    <span className="drive-details-value shipments-value">{dailsOrder?.orderTable?.consecutiveShipping}</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
        </div>
    );
}

export default OrdersCard;
