import React, { useEffect, useRef, useState } from 'react';
import {IncidentsCausesScreen} from '../screens/incidents-causes/ui/screens/IncidentsCausesScreen';
export default function CausesConfiguration() {

    return (
        <div>
           <IncidentsCausesScreen></IncidentsCausesScreen>
        </div>
    );
}
