import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Typography } from 'antd';

import {
    HeatMapOutlined,
    CloudUploadOutlined,
    ScheduleOutlined,
    WarningOutlined,
    SettingOutlined,
    LogoutOutlined,
    CalendarOutlined,
    DashboardOutlined,
    CodeSandboxOutlined

} from '@ant-design/icons';

import { useUser } from '../../../../context/UserContext';

import '../css/sidemenu.css';


const { Title } = Typography;


export const SideMenuScreen = (props) => {
    const location = useLocation();
    const { user } = useUser();

    const [roleName, setRoleName] = useState('');

    useEffect(() => {
        setRoleName(user.roleName);
    }, [user]);

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <Menu
                theme='light'
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
            >
                <Menu.Item key="general" className="menu-title-general">
                    <strong>General</strong>
                </Menu.Item>

                {roleName === 'admin' && (
                    <Menu.Item key='/' icon={<DashboardOutlined className="iconMenu" />} className="menuselected">
                        <Link to="/" onClick={() => props.clearSearchTarget()}>Dashboard</Link>
                    </Menu.Item>
                )}
                <Menu.Item key='/main-upload' icon={<CloudUploadOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/main-upload' onClick={() => props.clearSearchTarget()}>Cargue de datos</Link>
                </Menu.Item>
                <Menu.Item key='/scheduling' icon={<CalendarOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/scheduling' onClick={() => props.clearSearchTarget()}>Planificación</Link>
                </Menu.Item>
                <Menu.Item key='/home' icon={<HeatMapOutlined className="iconMenu" />} className="menuselected">
                    <Link to="/home" onClick={() => props.clearSearchTarget()}>Seguimiento Rutas</Link>
                </Menu.Item>
                <Menu.Item key='/completed-routes' icon={<ScheduleOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/completed-routes' onClick={() => props.clearSearchTarget()}>Rutas finalizadas</Link>
                </Menu.Item>
                <Menu.Item key='/completed-routesV2' icon={<ScheduleOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/completed-routesV2' onClick={() => props.clearSearchTarget()}>Rutas finalizadas V2</Link>
                </Menu.Item>
                <Menu.Item key='/onSiteDeliveries' icon={<CodeSandboxOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/onSiteDeliveries' onClick={() => props.clearSearchTarget()}>Entrega en Sitio</Link>
                </Menu.Item>
                <Menu.Item key='/incidents' icon={<WarningOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/incidents' onClick={() => props.clearSearchTarget()}>Incidentes</Link>
                </Menu.Item>

                <Menu.Item key="otros" className="menu-title">
                    <strong>Otros</strong>
                </Menu.Item>

                <Menu.Item key='/configuration' icon={<SettingOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/configuration' onClick={() => props.clearSearchTarget()}>Configuración</Link>
                </Menu.Item>
                <Menu.Item key='/configurationV2' icon={<SettingOutlined className="iconMenu" />} className="menuselected">
                    <Link to='/configurationV2' onClick={() => props.clearSearchTarget()}>Configuración V2</Link>
                </Menu.Item>
            </Menu>
        </>
    );
}
