import React from "react";
import { useEffect } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import DownloadIcon from "@mui/icons-material/Download";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Swal from "sweetalert2";
import { useState } from "react";
import axios from "axios";
import {
  insertOrdersTable,
  insertOrdersLine,
  fetchclients,
  InsertClients,
  InsertClientAddresses,
  insertClient,
  checkClientExists,
  UpdateClients,
} from "../../../TMS-Dynamics/queries/TMSqueries";
import calculateGeoEnclosure from "../../../../../../../services/geo-enclosure";
import localStorageService from "../../../../../../../services/localStorageService";
import loggerCooltrack from "../../../../../../../services/logger-cooltrack";
import { useLazyQuery, useMutation } from "@apollo/client";

const companyId = localStorageService.get("companyId");
const userId = localStorageService.get("id");

const ActionButtons = (props) => {
  const {
    isShippingSelected,
    isLoadSelected,
    selecteLoad,
    selectedShipment,
    sectors,
    distributionCenters,
    listShipping,
    dataOrderRunningg,
    dataOrderRunning,
    setListShipping,
    handleSelectShipping,
    setIsLoadSelected,
    loads,
    setAreAllSelected
  } = props;
  const [readyToSync, setReadyToSync] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [previousOrderNumberList, setPreviousOrderNumberList] = useState([]);
  const [packingSlip, setPackingSlip] = useState("");
  const [invoce, setInvoce] = useState("");

  const [insOrdersTable] = useMutation(insertOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [insOrdersLine] = useMutation(insertOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [insClients] = useMutation(InsertClients, {
    fetchPolicy: "no-cache",
  });
  const [updateClients] = useMutation(UpdateClients, {
    fetchPolicy: "no-cache",
  });

  const [insClientAddresses] = useMutation(InsertClientAddresses, {
    fetchPolicy: "no-cache",
  });
  const [getClients, dataClients] = useLazyQuery(fetchclients, {
    fetchPolicy: "no-cache",
  });

  const [createClientNew] = useMutation(insertClient, {
    fetchPolicy: "no-cache",
  });

  const [getCheckCliente, verficationExistClient] = useLazyQuery(
    checkClientExists,
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleSelectAllShipments = () => {
    const allShipments = selecteLoad.LoadLines;
    const updatedListShipping = [...listShipping];
    allShipments.forEach((shipping) => {
      const isSelected = updatedListShipping.some(
        (item) => item.ShipmentId === shipping.ShipmentId
      );
      if (!isSelected) {
        updatedListShipping.push(shipping);
      }
    });
    setListShipping(updatedListShipping);
  };

  const handleSyncLoad = () => {
    if (selecteLoad && selecteLoad.LoadLines.length > 0) {
      const firstShipment = selecteLoad.LoadLines[0];
      const updatedListShipping = [firstShipment, ...listShipping];
      console.log(updatedListShipping);

      setReadyToSync(true); // Establecer antes de actualizar listShipping
      setListShipping(updatedListShipping);
    }
  };

  const handleSyncSelection = () => {
    if (selecteLoad && selecteLoad.LoadLines.length > 0) {
      const firstShipment = selecteLoad.LoadLines[0];
      //const updatedListShipping = [firstShipment, ...listShipping];
      //console.log(updatedListShipping);

      setReadyToSync(true); // Establecer antes de actualizar listShipping
      //console.log(updatedListShipping);
      //setListShipping(updatedListShipping);
    }
  };

  const handleSelectAllShipmentsByLoad = () => {
    const allShipments = loads.flatMap(load => load.LoadLines);

    const newShipments = allShipments.filter(shipment =>
      !listShipping.some(existingShipment => existingShipment.ShipmentId === shipment.ShipmentId)
    );

    setListShipping(prevShipments => [...prevShipments, ...newShipments]);
    setAreAllSelected(true);
  };

  const handleSelectAllShipmentsByLoadSave = () => {
    const allShipments = loads.flatMap(load => load.LoadLines);

    const newShipments = allShipments.filter(shipment =>
      !listShipping.some(existingShipment => existingShipment.ShipmentId === shipment.ShipmentId)
    );

    setListShipping(prevShipments => [...prevShipments, ...newShipments]);
    setAreAllSelected(true);
    setReadyToSync(true);
  };

  useEffect(() => {
    const performSync = async () => {
      if (readyToSync && listShipping.length > 0) {
        console.log('Realizando sincronización con listShipping actualizado:', listShipping);
        await handleSave();
        setReadyToSync(false); // Restablecer después de la sincronización
      }
    };

    performSync();
  }, [listShipping, readyToSync]);

  useEffect(() => {
    // Suponiendo que `loads` contiene tus cargas y cada carga tiene un array de envíos `LoadLines`.
    const allShipmentsSelected = loads.every(load =>
      load.LoadLines.every(shipment =>
        listShipping.some(selected => selected.ShipmentId === shipment.ShipmentId)
      )
    );

    // Actualiza `areAllSelected` basado en si todos los envíos de todas las cargas están seleccionados
    setAreAllSelected(allShipmentsSelected);
  }, [listShipping, loads]);

  const calculateTotalSizeAndVolume = (containers) => {
    let totalHeight = 0;
    let totalWidth = 0;
    let totalDepth = 0;
    let totalVolume = 0;
    let totalWeight = 0;

    containers.forEach((container) => {
      totalHeight += container.height;

      if (container.width > totalWidth) {
        totalWidth = container.width;
      }

      if (container.depth > totalDepth) {
        totalDepth = container.depth;
      }

      totalVolume += container.height * container.width * container.depth;
      totalWeight += container.weight;
    });

    return {
      totalSize: {
        height: totalHeight,
        width: totalWidth,
        depth: totalDepth,
      },
      totalVolume,
      totalWeight,
    };
  };

  const validateExistsShipment = async (shipment) => {
    const exists = props.dataOrderRunning?.data?.ordersTable.some((order) => {
      if (shipment.ShipmentId && shipment.ShipmentId !== "") {
        return order.consecutiveShipping === shipment.ShipmentId;
      }

      return false;
    });
    /* if (exists) {
      setSyncInProgress(false);
      Swal.fire({
        title: "Orden de venta o TRN existente",
        text: `El envio ${shipment.ShipmentId} fue sincronizado anteriormente, por esta razón fue ignorado para la sincronización`,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }
    */
    return exists;
  };

  const handleSave = async () => {
    if (
      selecteLoad?.LoadLines[0]?.InvoiceList?.length === 0 &&
      selecteLoad?.LoadLines[0]?.PackingSlipList?.length === 0
    ) {
      Swal.fire({
        title: "Error de Sincronización",
        text: `El envio ${selectedShipment[0]?.ShipmentId} no puede ser sincronizado ya que no cuenta con un numero de factura y remisión`,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    } else {
      let orders = [];
      setSyncInProgress(true);
      console.log(selecteLoad);
      console.log(listShipping.length);
      if (listShipping.length > 0) {
        const orderStatusList = [];
        let orderNumberList = "";

        const loadingAlert = Swal.fire({
          title: "Sincronizando Envíos",
          html: '<i class="fas fa-spinner fa-spin"></i> Por favor, espere...',
          showConfirmButton: false,
          allowOutsideClick: false,
        });


        for (let i = 0; i < listShipping.length; i++) {
          const shipment = listShipping[i];
          console.log(shipment);
          const exists = await validateExistsShipment(shipment);
          console.log(exists);

          if (!exists) {
            let newAddress = "";
            console.log(exists + "Hola ");
            const address = shipment.Street.replace(",", " ")
              .replace(/\s\s+/g, " ")
              .replace("#", "")
              .split(" ");

            address.forEach((item) => {
              newAddress += item + "+";
            });

            newAddress = newAddress.substring(0, newAddress.length - 1);

            newAddress += `+${shipment.City}+${shipment.State}+${shipment.Country}`;

            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
            );

            let sector = calculateGeoEnclosure(
              {
                lat: res.data.results[0].geometry.location.lat,
                lng: res.data.results[0].geometry.location.lng,
              },
              sectors
            );

            if (!sector) {
              sector = sectors.find((item) => item.name === "Importados TMS");
            }

            let weight = 0;
            let height = 0;
            let width = 0;
            let depth = 0;
            let totalSize = 0;
            let totalVolume = 0;
            let individualCubic = 0;
            let totalContainers = 0;

            if (shipment.ContainerList.length > 0) {
              const containersList = shipment.ContainerList.map((container) => {
                return {
                  id: container.ContainerId,
                  weight:
                    container.WeightUnit === "kg"
                      ? container.Weight ?? 0
                      : 0.453592 * (container.Weight ?? 0),
                  height: container.Height ?? 0,
                  width: container.Width ?? 0,
                  depth: container.Length ?? 0,
                };
              });

              const containerResponse =
                calculateTotalSizeAndVolume(containersList);

              totalSize = containerResponse.totalSize;
              totalVolume = containerResponse.totalVolume;
              weight = containerResponse.totalWeight;
              height = containerResponse.totalSize.height;
              width = containerResponse.totalSize.width;
              depth = containerResponse.totalSize.depth;
              individualCubic =
                containerResponse.totalVolume / containersList.length;
              totalContainers = containersList.length;
            }

            let custIdentificationNumber;
            let custFullName;
            let custPhone = shipment.ContactPhone;
            let distributionCenter;
            switch (shipment.Type) {
              case "Envío de pedidos de transferencia":
                custIdentificationNumber = shipment.InventLocationIdTo;
                distributionCenter = distributionCenters.find(
                  (center) => center.name === shipment.InventLocationIdTo
                );
                custFullName =
                  "TRN - " + distributionCenter?.description === undefined
                    ? "N/D"
                    : shipment.InventLocationIdTo;
                custPhone = distributionCenter?.phone;
                break;
              case "Pedido de ventas":
                custIdentificationNumber = shipment.CustAccount;
                custFullName = shipment.SalesName;
                break;
              default:
                custIdentificationNumber = shipment.OrderAccount;
                custFullName = shipment.PurchName;
                break;
            }

            let sequenceResponse = await axios({
              method: "POST",
              url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
              data: { companyId, sequenceTypeId: "orders" },
            });

            let sequence = sequenceResponse.data.sequence;

            if (previousOrderNumberList.includes(sequence)) {
              while (previousOrderNumberList.includes(sequence)) {
                sequenceResponse = await axios({
                  method: "POST",
                  url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
                  data: { companyId, sequenceTypeId: "orders" },
                });
                sequence = sequenceResponse.data.sequence;
              }
            }
            for (
              let i = 0;
              i < selecteLoad?.LoadLines[0]?.PackingSlipList?.length;
              i++
            ) {
              const PackingSlipList =
                selecteLoad?.LoadLines[0]?.PackingSlipList[i];
              setPackingSlip(PackingSlipList.PackingSlipId);
            }

            let PaymentOnCashValue = 0;

            for (
              let j = 0;
              j < selecteLoad?.LoadLines[0]?.InvoiceList?.length;
              j++
            ) {
              const InvoiceList = selecteLoad?.LoadLines[0]?.InvoiceList[j];
              setInvoce(InvoiceList.InvoiceId);
              if (InvoiceList.Payment === "CONTRAENTREGA") {
                PaymentOnCashValue += InvoiceList.InvoiceAmount;
              }
            }

            setPreviousOrderNumberList([...previousOrderNumberList, sequence]);

            const ClientsResponses = await getClients({
              variables: {
                nit: shipment.CustAccount,
              },
            });

            let customerSupplierId = "";

            if (
              shipment.CustAccount !== "" &&
              ClientsResponses?.data?.clients?.length === 0
            ) {
              const variablesInsClient = {
                nit: shipment.CustAccount,
                name: shipment.SalesName,
                companyId: companyId,
                email: shipment.ContactEmail,
                accountNumber: shipment.CustAccount,
                documentType: "NIT",
                currency: "Pesos colombianos",
                personType: "organization",
                person: "customer",
                typePerson: "Persona jurídica",
              };
              const _insertClients = await insClients({
                variables: {
                  objects: [variablesInsClient],
                },
              });

              customerSupplierId =
                _insertClients?.data?.insert_clients?.returning[0]?.id;
              if (_insertClients?.data?.insert_clients?.returning?.length > 0) {
                const variablesInsClientAddresses = {
                  clientId:
                    _insertClients?.data?.insert_clients?.returning[0]?.id,
                  name: "Bodega Principal",
                  contactName: shipment.ContactName,
                  phoneNumber: shipment.ContactPhone,
                  cellPhoneNumber: shipment.ContactPhone,
                  address: shipment.Street,
                  state: shipment.State,
                  city: shipment.City,
                  addressComplement: "",
                  lat: `${res.data.results[0].geometry.location.lat}`,
                  lng: `${res.data.results[0].geometry.location.lng}`,
                  sectorId: sector.id,
                  enabled: true,
                  principal: true,
                };
                await insClientAddresses({
                  variables: {
                    objects: [variablesInsClientAddresses],
                  },
                });
              }
            }
            if (
              (shipment.CustAccount !== "" &&
                ClientsResponses?.data?.clients[0]?.typePerson === "") ||
              ClientsResponses?.data?.clients[0]?.typePerson === null ||
              ClientsResponses?.data?.clients[0]?.typePerson === undefined
            ) {
              const _updateTypePerson = await updateClients({
                variables: {
                  nit: shipment.CustAccount,
                  typePerson: "Persona jurídica",
                },
              });
            }
            const requestOrder = {
              orderNumber: sequence,
              customerSupplierId:
                shipment.CustAccount !== "" &&
                  ClientsResponses?.data?.clients?.length === 0
                  ? customerSupplierId
                  : ClientsResponses?.data?.clients[0]?.id,
              address: shipment.Street,
              city: shipment.City,
              state: shipment.State,
              addressComplement: shipment.BuildingCompliment,
              sectorId: sector.id,
              custIdentificationNumber,
              custFullName,
              custPhoneNumber: custPhone,
              type:
                shipment.Type === "Envío de pedidos de transferencia"
                  ? "warehousesTransfer"
                  : shipment.Type === "Pedido de compra"
                    ? "pickup"
                    : "delivery",
              totalOrderAmount: PaymentOnCashValue,
              destination: `${res.data.results[0].geometry.location.lat},${res.data.results[0].geometry.location.lng}`,
              weight,
              height,
              width,
              depth,
              cubicMeters: individualCubic,
              totalCubicMeters: totalVolume,
              orderedQuantity: totalContainers,
              linesDetail: true,
              notes: shipment.Type,
              paymentOrCashOnDeliveryRequired: PaymentOnCashValue > 0,
              consecutiveSaleOrder: shipment.OrderNum,
              consecutiveShipping: shipment.ShipmentId,
              createByUserId: userId,
              typePerson: "Persona jurídica",
              distributionCenterId:
                distributionCenters.find(
                  (item) => item.name === selecteLoad.InventLocationId
                )?.id ?? distributionCenters[0].id,
              consecutiveBurden: shipment.LoadId,
              consecutiveRemission: (() => {
                if (
                  !selecteLoad?.LoadLines[0]?.ItemList ||
                  selecteLoad?.LoadLines[0]?.ItemList.length === 0
                ) {
                  return "";
                }

                const uniquePackingSlipIds = new Set();

                shipment.ItemList?.forEach((item) => {
                  uniquePackingSlipIds.add(item.PackingSlipId);
                });

                return Array.from(uniquePackingSlipIds).toString();
              })(),
              consecutiveBill: (() => {
                if (
                  !selecteLoad?.LoadLines[0]?.ItemList ||
                  selecteLoad?.LoadLines[0]?.ItemList.length === 0
                ) {
                  return "";
                }

                const uniqueInvoiceIds = new Set();

                shipment.ItemList?.forEach((item) => {
                  uniqueInvoiceIds.add(item.InvoiceId);
                });

                return Array.from(uniqueInvoiceIds).toString();
              })(),
              priority: "a_high",
              custEmail: "adavid@navitrans.com.co",
              paymentMethod: shipment.PaymMode,
              companyId: "482777f8-95e3-4b2a-8a78-7c75aa733946",
              enablePackageDimensions: true,
              packageId: "380ed460-52a7-4e88-8c2d-42cdfa31ea87",
            };
            console.log(requestOrder);
            const resOrder = await insOrdersTable({
              variables: {
                objects: [requestOrder],
              },
            });
            console.log("inserto OrderTable");

            orders.push(resOrder);

            if (orders.length > 0) {
              const order =
                resOrder["data"]["insert_ordersTable"]["returning"][0];
              orderNumberList += order.orderNumber + ", ";
              const data = shipment.ItemList;
              const _dataLines = [];

              const _InvoiceList = shipment.InvoiceList;



              for (let i = 0; i < data.length; i++) {
                const line = data[i];

                let externalInvoiceId = "";

                let externalSalesId = "";

                if (_InvoiceList) {
                  const invoiceRecId = _InvoiceList?.find(
                    (invoice) => invoice.InvoiceId === line.InvoiceId
                  )?.RecId;
                  if (invoiceRecId) {
                    externalInvoiceId = invoiceRecId.toString();
                  }
                }

                if (shipment?.SalesRecId) {
                  externalSalesId = shipment.SalesRecId?.toString();
                }

                _dataLines.push({
                  orderNumber: order.orderNumber,
                  productNumber: line.ItemId,
                  productName: line.ProductName,
                  orderedQuantity: line.ProductQuantity,
                  initialQuantity: line.ProductQuantity,
                  Invoice:
                    line.TRN === "" ||
                      line.TRN === null ||
                      line.TRN === undefined
                      ? line.InvoiceId
                      : line.TRN,
                  PackingSlip: line.PackingSlipId,
                  externalId: line.RecId?.toString(),
                  externalInvoiceId,
                  externalSalesId,
                });
              }

              let resLine;
              resLine = await insOrdersLine({
                variables: {
                  objects: _dataLines,
                },
              });
              setSyncInProgress(false);
              if (resLine.data.insert_ordersLine.affected_rows > 0) {
                /*
                const identification = shipment.CustAccount
                if(identification !==""){

                const checkClient = await getCheckCliente({variables : {nit: identification}});
                console.log(checkClient);
                console.log(checkClient.data.clients.length);
                if(checkClient.data.clients.length == 0)
                {      
                   await createClientNew({
                    variables: {
                      personType : "organization",
                      person : "customer", 
                      documentType: "NIT",
                      nit: shipment.CustAccount,
                      name : shipment.SalesName,
                      accountNumber : shipment.CustAccount,
                      currency : "Pesos colombianos ",
                      email : shipment.ContactEmail,
                      enabled : true,
                      companyId : companyId,
                    }
                      
                  })
                  console.log("Cliente creado"); 
                }
                }
                
                */
                const id = resLine.data.insert_ordersLine.returning[0].id;

                const orderStatus = {
                  shipmentId: shipment.ShipmentId,
                  orderNumber: order.orderNumber,
                  status: "Sincronizado",
                };

                orderStatusList.push(orderStatus);

              } else {
                setSyncInProgress(false);
                const orderStatus = {
                  shipmentId: shipment.ShipmentId,
                  orderNumber: "",
                  status: "No sincronizado, ya se sincronizo anteriormente",
                };

                orderStatusList.push(orderStatus);
              }
            }

            props.getOrderRunning();
          }
          else{
            setSyncInProgress(false);
                const orderStatus = {
                  shipmentId: shipment.ShipmentId,
                  orderNumber: "",
                  status: "No sincronizado, ya se sincronizo anteriormente",
                };

                orderStatusList.push(orderStatus);
          }
          loadingAlert.close();
          updateSyncStatus(orderStatusList);
        }
      }
    }
    setSyncInProgress(false);
    //getTMSLoad(dataOrderRunningg,CenterUser,selectedPageSize,selectedPageId,filterr)
  };

  const updateSyncStatus = (statusList) => {
    Swal.fire({
      title: "Estado de Sincronización de Envíos",
      html: `
        <div style="max-height: 400px; overflow-y: auto;">
          <table>
            <thead>
              <tr>
                <th>Envío</th>
                <th>Número de Orden</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              ${statusList
          .map(
            (status) => `
                    <tr>
                      <td>${status.shipmentId}</td>
                      <td>${status.orderNumber}</td>
                      <td>${status.status}</td>
                    </tr>
                  `
          )
          .join("")}
            </tbody>
          </table>
        </div>
      `,
      showConfirmButton: true,
    });
  };

  return (
    <div className="action-buttons-container">
      <div className="container-buttons">
        <button
          className="action-button"
          disabled={listShipping.length > 0}
          onClick={() => handleSyncLoad()}
        >
          <SyncIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Carga</p>
      </div>
      <div className="container-buttons">
        <button
          className="action-button"
          disabled={listShipping.length <= 0}
          onClick={() => {
            handleSave();
          }}
        >
          <DownloadIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Envios</p>
      </div>
      <div className="container-buttons">
        <button
          className="action-button"
          disabled={isLoadSelected}
          onClick={handleSelectAllShipments}
        >
          <ChecklistRtlIcon className="icon-action" />
        </button>
        <p>Seleccionar</p>
        <p>Todos los Envios</p>
      </div>
      <div className="container-buttons">
        <button className="action-button" onClick={() => handleSyncSelection()}>
          <PublishedWithChangesIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Seleccion</p>
      </div>
      <div className="container-buttons">
        <button className="action-button" disabled={listShipping.length > 0} onClick={handleSelectAllShipmentsByLoad}>
          <DoneAllIcon className="icon-action" />
        </button>
        <p>Seleccionar</p>
        <p>Todas las Cargas</p>
      </div>
      <div className="container-buttons">
        <button className="action-button" disabled={listShipping.length > 0} onClick={handleSelectAllShipmentsByLoadSave}>
          <SyncIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Todas las Cargas</p>
      </div>
    </div>
  );
};

export default ActionButtons;
