import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Descriptions, Input, Card, Button, Select, Space, Skeleton, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CarOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { handleSave, handleDelete, handleEdit, handleClear, getColumnSearchPropsText } from './UseCases';


import { 
    fetchIncidentManagement,
    insertIncidentManagement,
    updateIncidentManagement,
    deleteIncidentManagement,
    fetchIncidents
} from '../../queries/incidentManagementQueries';

import { IncidentManagementIndicator } from './IncidentManagementIndicator';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

export const IncidentManagementScreen = () => {
    const [getIncidentManagement, dataIncidentManagement] = useLazyQuery(fetchIncidentManagement, { fetchPolicy: 'no-cache' });
    const [getIncidents, dataIncidents] = useLazyQuery(fetchIncidents, {fetchPolicy: 'no-cache' });
    const [insIncidentsCause] = useMutation(insertIncidentManagement, { fetchPolicy: 'no-cache' });
    const [delIncidentsCause] = useMutation(deleteIncidentManagement, { fetchPolicy: 'no-cache' });
    const [updIncidentsCause] = useMutation(updateIncidentManagement, { fetchPolicy: 'no-cache' });
    const [incident, setIncident] = useState('');
    const [incidentManagement, setIncidentManagement] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");
    

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getIncidentManagement();
        getIncidents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    useEffect(() => {
        setIncidentManagement([]);

        if(!dataIncidentManagement.loading && dataIncidentManagement.data && dataIncidentManagement.data.incidentManagement.length > 0) {
            dataIncidentManagement.data.incidentManagement.map((item) => {
                const deleteable = true;
                const { id, description, enabled, incident, emailNotification, emailMessage, appNotification, appMessage } = item;
                
                return setIncidentManagement((current) => [...current, {
                    id,
                    description,
                    incidentId: incident === null ? "none" : incident.id,
                    incidentName: incident === null ? "none" : incident.description,
                    emailNotification,
                    emailMessage: emailMessage ?? "",
                    appNotification,
                    appMessage: appMessage ?? "",
                    enabled,
                    deleteable
                }]);
            });
        }
    }, [dataIncidentManagement.loading, dataIncidentManagement.data]);

    const columns = [
        {
            title: 'Gestión',
            key: 'description',
            dataIndex: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            ...getColumnSearchPropsText("Gestión", "description")
        },
        {
            title: 'Tipo de Incidente',
            key: 'incidentName',
            dataIndex: 'incidentName',
            sorter: (a, b) => a.incidentName.localeCompare(b.incidentName),
            ...getColumnSearchPropsText("Tipo", "incidentName")
        },
        {
            title: 'Notificación de Email',
            key: 'emailNotification',
            dataIndex: 'emailNotification',
            align: 'center',
            sorter: {
                compare: (a, b) => a.emailNotification - b.emailNotification,
            },
            render: (value) => <Switch checked={value} disabled />
        },
        {
            title: 'Mensaje Notificación Email',
            key: 'emailMessage',
            dataIndex: 'emailMessage',
            sorter: (a, b) => a.emailMessage.localeCompare(b.emailMessage),
            ...getColumnSearchPropsText("Mensaje email", "emailMessage")
        },
        {
            title: 'Notificación de App',
            key: 'appNotification',
            dataIndex: 'appNotification',
            align: 'center',
            sorter: {
                compare: (a, b) => a.appNotification - b.appNotification,
            },
            render: (value) => <Switch checked={value} disabled />
        },
        {
            title: 'Mensaje Notificación App',
            key: 'appMessage',
            dataIndex: 'appMessage',
            sorter: (a, b) => a.appMessage.localeCompare(b.appMessage),
            ...getColumnSearchPropsText("Mensaje app", "appMessage")
        },
        {
            title: 'Habilitado',
            key: 'enabled',
            dataIndex: 'enabled',
            align: 'center',
            sorter: {
                compare: (a, b) => a.enabled - b.enabled,
            },
            render: (value) => <Switch checked={value} disabled />
        }
    ];
    const formItemLayout = {
        labelCol: {
          lg: { span:11 },
        },
        wrapperCol: {
          lg: { span: 15 },
        },
      };
    return (
        <Col className='children-inner-container-with-bg'>
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <IncidentManagementIndicator value={incidentManagement.length} label={"Total Gestiones"}><CarOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></IncidentManagementIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <IncidentManagementIndicator value={(incidentManagement.filter(item => item.enabled)).length} label={"Gestiones Activas"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></IncidentManagementIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <IncidentManagementIndicator value={(incidentManagement.filter(item => !item.enabled)).length} label={"Gestiones Inactivas"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></IncidentManagementIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow, delIncidentsCause, incidentManagement, setIncidentManagement, setClickedRow)} disabled={clickedRow.length === 0}>Eliminar</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataIncidentManagement.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={incidentManagement}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Gestión' : 'Editar Gestión'} width={500}>
                <Form
                {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave({ ...values, incidentName: incident }, insIncidentsCause, updIncidentsCause, incidentManagement, setIncidentManagement, isEditing, setIsEditing, form);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        description: '',
                        incidentId: undefined,
                        enabled: true,
                        deleteable: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                
                            <Form.Item
                                label='Gestión'
                                labelAlign='left' 
                                name='description'
                                rules={[{ required: true, message: 'Por favor ingrese una causa'}]}
                            >
                                <Input />
                            </Form.Item>
                    
                            <Form.Item 
                                label='Tipo de incidente'
                                labelAlign='left'
                                name='incidentId'
                                rules={[{ required: true, message: 'Por favor seleccione una opción'}]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if(value !== undefined) {
                                            setIncident(object.children);
                                            return form.setFieldsValue({ incidentId: value, incidentName: object.children })
                                        }
                                    }}
                                >
                                    {
                                        dataIncidents && dataIncidents.data && dataIncidents.data.incidents.map((item) => {
                                            return <Select.Option key={item.id} value={item.id}>{item.description}</Select.Option>
                                        })
                                    }
                                    
                                </Select>
                            </Form.Item>
                    
                            <Form.Item labelAlign='left' label='Habilitar Notificación de Email' name='emailNotification' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                       
                            <Form.Item
                            label='Mensaje Notificación Email'
                            labelAlign='left'
                                name='emailMessage'
                                rules={[{ message: 'Por favor ingrese una causa'}]}
                            >
                                <Input />
                            </Form.Item>
                
                            <Form.Item labelAlign='left' label='Habilitar Notificación de App' name='appNotification' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                    
                            <Form.Item
                                label='Mensaje Notificación App'
                                labelAlign='left'
                                name='appMessage'
                                rules={[{ message: 'Por favor ingrese una causa'}]}
                            >
                                <Input />
                            </Form.Item>
                      
                            <Form.Item labelAlign='left' label='Habilitado' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                 
                    <Form.Item>
                        <Space>
                            <Button 
                                type='primary' 
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={() => {
                                    handleClear(setIsEditing, form);
                                    setClickedRow("");
                                    handleCancel();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}
