import React, { useState, useEffect } from "react";
import {
  Form,
  Descriptions,
  Input,
  Checkbox,
  Card,
  Button,
  Space,
  Select,
  InputNumber,
  Switch,
  DatePicker,
  Row,
  Col,
} from "antd";
import {
  SaveFilled,
  ArrowLeftOutlined,
  FormOutlined,
  UserAddOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import loggerCooltrack from "../../../../../services/logger-cooltrack";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  fetchClients,
  fetchSectors,
  fetchCustomers,
  fetchWarehouses,
  fetchAddressBook,
  fetchPackages,
  insertOrdersTable,
  insertOrdersLine,
  fetchOrderByOrderNumber,
  fetchFields,
  fetchOrders,
  updateOrdersTable,
  deleteOrdersLine,
  insertOrdersTableNoLines,
  fetchUsers,
  fetchClientAddresess,
  fetchPaymentMethods,
  checkClientExists,
  insertClient,
  updateTypePerson,
  OrdeNumberEdit,
} from "../../../queries/uploadData";
import { priorityList } from "../../../../configuration/common/commonData";
import axios from "axios";
import { fetchUsersVehicles } from "../../../../home/queries/homeQueries";
import {
  insertRoute,
  updateuploadDataFieldsController,
} from "../../../queries/uploadData";
import LinesUploadScreen from "./LinesUploadScreen";
import { isValidLatLng } from "../../../../../helpers/utils";
import { subscriptionOrdersInVehicleTempCreateByUserId } from "../../../../scheduling/queries/schedulingQueries";
import localStorageService from "../../../../../services/localStorageService";
import "./styles/HeaderUploadScreen.css";
import { UserNew } from "./UserNew";
import { AddressNew } from "./AddressNew";
import calculateGeoEnclosure from "../../../../../services/geo-enclosure";
import { Label } from "recharts";
import moment from "moment";

const role = localStorageService.get("role");
const companyId = localStorageService.get("companyId");
const id = localStorageService.get("id");
const userid = localStorageService.get("id");
const userDistributionCenterId = localStorageService.get(
  "userDistributionCenterId"
);
const orderTypes = [
  { label: "Envío", value: "delivery" },
  { label: "Recogida", value: "pickup" },
  { label: "Servicio Especial", value: "customService" },
  { label: "Traslado entre Bodegas", value: "warehousesTransfer" },
];

export const HeaderUploadScreen = () => {
  const [form] = Form.useForm();
  const apolloClient = useApolloClient();
  const [hasOrder, setHasOrder] = useState(false);
  const [ordersTable, setOrdersTable] = useState({});
  const [ordersLine, setOrdersLine] = useState([]);
  const [hasLineErrors, setHasLineErrors] = useState(true);
  const [dataRoute, setDataRoute] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [ordersLines, setOrdersLines] = useState([]);
  const [clientAddresses, setClientAddresses] = useState([]);
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [identificationClient, setIdentificationClient] = useState([]);
  const [sequenceOrderNumber, setSequenceOrderNumber] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDrawerAddress, setShowDrawerAddress] = useState(false);
  const [clear, setClear] = useState(false);
  const [formType, setFormType] = useState("");
  const [customerSupplier, setCustomerSupplier] = useState("");
  const [formDistributionCenter, setFormDistributionCenter] = useState({});
  const [formCustomer, setFormCustomer] = useState({});
  const [identificationVerification, setIdentificationVerification] =
    useState(false);
  const [
    formDistributionCenterDestination,
    setFormDistributionCenterDestination,
  ] = useState({});
  const [formAddressBook, setFormAddressBook] = useState({});
  const [
    formPaymentOrCashOnDeliveryRequired,
    setFormPaymentOrCashOnDeliveryRequired,
  ] = useState(false);
  const [
    formPaymentOrCashOnDeliveryRequiredd,
    setFormPaymentOrCashOnDeliveryRequiredd,
  ] = useState(false);
  const [formEnableDeliveryWindow, setFormEnableDeliveryWindow] =
    useState(false);
  const [formEnablePackageDimensions, setFormEnablePackageDimensions] =
    useState(false);
  const [formPackage, setFormPackage] = useState({});
  const [formLinesDetail, setFormLinesDetail] = useState(false);
  const [formSinLinesDetail, setFormSinLinesDetail] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(false);
  const [geoClouser, setGeoClouser] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSectorName, setSelectedSectorName] = useState("");
  const [isSelectActive, setIsSelectActive] = useState(true);
  const [dataOrdenNumEdit, setDataOrdenNumEdit] = useState();
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });
  const [getClient, dataClient] = useLazyQuery(fetchClients, {
    fetchPolicy: "no-cache",
  });
  const [getPaymentMethods, dataPaymentMethods] = useLazyQuery(
    fetchPaymentMethods,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getUserId, dataUserId] = useLazyQuery(fetchUsers, {
    fetchPolicy: "no-cache",
  });
  const [getCustomers, dataCustomers] = useLazyQuery(fetchCustomers, {
    fetchPolicy: "no-cache",
  });
  const [getWarehouses, dataWarehouses] = useLazyQuery(fetchWarehouses, {
    fetchPolicy: "no-cache",
  });

  const [getAddressBook, dataAddressBook] = useLazyQuery(fetchAddressBook, {
    fetchPolicy: "no-cache",
  });

  const [getPackages, dataPackages] = useLazyQuery(fetchPackages, {
    fetchPolicy: "no-cache",
  });

  const [getRouteRunning, dataRouteRunning] = useLazyQuery(fetchUsersVehicles, {
    fetchPolicy: "no-cache",
  });
  const [getOrderRunning, dataOrderRunning] = useLazyQuery(fetchOrders, {
    fetchPolicy: "no-cache",
  });
  const [getOrdeNumberEdit] = useLazyQuery(OrdeNumberEdit, {
    fetchPolicy: "no-cache",
  });
  const [getFields, dataFields] = useLazyQuery(fetchFields, {
    fetchPolicy: "no-cache",
  });
  const [getCheckCliente, verficationExistClient] = useLazyQuery(
    checkClientExists,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [updateTypePersons] = useMutation(updateTypePerson, {
    fetchPolicy: "no-cache",
  });
  const [insOrdersTable] = useMutation(insertOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [instOrdersTableNoLines] = useMutation(insertOrdersTableNoLines, {
    fetchPolicy: "no-cache",
  });
  const [uptOrdersTable] = useMutation(updateOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [dltOrdersTable] = useMutation(deleteOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [createClientNew] = useMutation(insertClient, {
    fetchPolicy: "no-cache",
  });

  const [addRoute] = useMutation(insertRoute, { fetchPolicy: "no-cache" });
  const [updateUploadDataController] = useMutation(
    updateuploadDataFieldsController,
    { fetchPolicy: "no-cache" }
  );

  const hasEmptyFields = () => {
    const allLinesFilled = ordersLine.every((line) => {
      return Boolean(line.productName) && Boolean(line.productNumber);
    });
    if (
      !form.getFieldValue("orderNumber") ||
      !form.getFieldValue("distributionCenterId") ||
      !form.getFieldValue("type") ||
      !form.getFieldValue("custFullName") ||
      !form.getFieldValue("address") ||
      !form.getFieldValue("destination") ||
      !form.getFieldValue("sectorId") ||
      !form.getFieldValue("priority") ||
      !form.getFieldValue("custIdentificationNumber") ||
      !form.getFieldValue("custPhoneNumber") ||
      !form.getFieldValue("notes") ||
      !form.getFieldValue("typePerson")
    ) {
      Swal.fire({
        title: "Campos vacios",
        text: `Hace falta diligenciar campos, por favor fijarse en los campos con asteriscos rojos`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (!form.getFieldValue("editOrderNumber") && isUpdate === true) {
      Swal.fire({
        title: "Campos orden vacío",
        text: `Por favor elige una orden a editar más arriba`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      !form.getFieldValue("destinationDistributionCenterId") &&
      formType === "warehousesTransfer"
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Hace falta diligenciar el campo Cliente/Proveedor/Centro`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "delivery" &&
      !form.getFieldValue("consecutiveSaleOrder") &&
      !form.getFieldValue("consecutiveRemission") &&
      !form.getFieldValue("consecutiveBill")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar numero de factura o remisión o pedido de venta`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "pickup" &&
      !form.getFieldValue("consecutiveBill") &&
      !form.getFieldValue("consecutiveRemission")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de factura o remisión`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "warehousesTransfer" &&
      !form.getFieldValue("consecutiveBurden") &&
      !form.getFieldValue("consecutiveShipping") &&
      !form.getFieldValue("consecutiveTRM")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de traslado o número del envío o número de la carga`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formPaymentOrCashOnDeliveryRequired === true &&
      !form.getFieldValue("totalOrderAmount")
    ) {
      Swal.fire({
        title: "Campo de requiere cobro activo",
        text: "Se debe diligenciar el monto total",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryStartTime") &&
        !form.getFieldValue("expectedDeliveryEndTime")) ||
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryStartTime")) ||
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryEndTime"))
    ) {
      Swal.fire({
        title: "Compromiso de entrega activo",
        text: "Por favor elige una fecha de inicio a fin de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formEnablePackageDimensions === true &&
      !form.getFieldValue("packageId")
    ) {
      Swal.fire({
        title: "Tipo de paquete vacío",
        text: "Por favor ingrese el tipo de paquete de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (formLinesDetail === true && allLinesFilled === false) {
      Swal.fire({
        title: "Detalle de línea vacío",
        text: "Por favor ingrese el código y nombre del producto",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (!form.getFieldValue("notes")) {
      Swal.fire({
        title: "Detalle de nota",
        text: "Por favor ingrese una nota relacionada a la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (!form.getFieldValue("typePerson")) {
      Swal.fire({
        title: "Tipo de persona",
        text: "Por favor ingrese el tipo de persona",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else {
      setFormSinLinesDetail(true);
    }
  };

  useEffect(() => {
    const loadInitData = async () => {
      await secuenciaNumerica(false);
      getSectors();
      getPaymentMethods();
      getClient({
        variables: {
          companyId,
        },
      });
      getCustomers({
        variables: {
          companyId,
        },
      });
      getUserId({
        variables: {
          id,
        },
      });
      getWarehouses({
        variables: {
          companyId,
        },
      });

      getPackages({
        variables: {
          companyId,
        },
      });

      getRouteRunning();
      funOrdeNumberEdit();
      getFields({
        variables: {
          tableName: "ordersTable",
        },
      });
    };
    loadInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const secuenciaNumerica = async (force) => {
    let _sequenceOrderNumber = sequenceOrderNumber;
    if (force) {
      setSequenceOrderNumber(null);
      _sequenceOrderNumber = null;
    }
    if (!_sequenceOrderNumber) {
      try {
        const responseSequence = await axios({
          method: "POST",
          url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
          data: { companyId: companyId, sequenceTypeId: "orders" },
        });
        if (responseSequence.status === 200) {
          setSequenceOrderNumber(responseSequence.data.sequence);
          _sequenceOrderNumber = responseSequence.data.sequence;
          form.setFieldsValue({
            id: "",
            orderNumber: _sequenceOrderNumber,
            address: "",
            addressComplement: "",
            sectorId: "",
            sectorName: "",
            custIdentificationNumber: "",
            custFullName: "",
            custPhoneNumber: "",
            paymentMethod: "",
            totalOrderAmount: 0,
            destination: "",
            height: 0,
            width: 0,
            depth: 0,
            weight: 0,
            custEmail: "",
            priority: "",
            priorityName: "",
            routeRoutingId: "",
            routeRoutingName: "",
            orderRoutingId: "",
            orderRoutingName: "",
            city: "",
            state: "",
            notes: "",
            distributionCenterId: userDistributionCenterId,
            expectedDeliveryStartTime: "",
            expectedDeliveryEndTime: "",
            customerSupplierId: "",
            addressBookId: "",
            consecutiveBill: "",
            consecutiveRemission: "",
            consecutiveTRM: "",
            consecutiveSaleOrder: "",
            consecutiveShipping: "",
            consecutiveBurden: "",
            type: orderTypes[2]?.value !== null ? orderTypes[2]?.value : "",
            cubicMeters: 0,
            orderedQuantity: 0,
            packageId: "",
            packageContent: "",
            destinationDistributionCenterId: "",
            linesDetail: false,
            paymentOrCashOnDeliveryRequired: false,
            enableDeliveryWindow: false,
            totalCubicMeters: 0,
            enablePackageDimensions: false,
            typePerson: "",
          });
          setFormType(orderTypes[2]?.value);
        }
      } catch (error) {
        console.error(error.toString());
      }
    }
    return _sequenceOrderNumber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  useEffect(() => {
    if (formCustomer && formCustomer.id !== undefined) {
      getAddressBook({
        variables: {
          clientId: formCustomer.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCustomer]);

  useEffect(() => {
    if (dataAddressBook?.data?.clientAddresses?.length > 0) {
      const dataAddressBookId = dataAddressBook?.data?.clientAddresses.map(
        (item) => ({ ...item })
      );
      for (let index = 0; index < dataAddressBookId.length; index++) {
        const element = dataAddressBookId[index];
        form.setFieldsValue({
          addressBookId:
            element.principal === true
              ? element.id
              : dataAddressBook?.data?.clientAddresses[0]?.id,
          addressBookName:
            element.principal === true
              ? element.name
              : dataAddressBook?.data?.clientAddresses[0]?.name,
        });
        setSelectedAddress(true);
        updateAddressBook(
          dataAddressBook.data?.clientAddresses?.filter((item) =>
            element.principal === true
              ? item.id === element.id
              : item.id === dataAddressBook?.data?.clientAddresses[0]?.id
          )[0]
        );
      }
    } else if (dataAddressBook?.data?.clientAddresses?.length === 0) {
      ResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddressBook]);

  const ResetForm = () => {
    form.setFieldsValue({
      addressBookId: "",
      address: "",
      addressComplement: "",
      sectorId: "",
      sectorName: "",
      custFullName: "",
      custPhoneNumber: "",
      paymentMethod: "",
      destination: "",
      custEmail: "",
      priority: "",
      priorityName: "",
      city: "",
      state: "",
      notes: "",
    });
  };
  useEffect(() => {
    setDistributionCenters([]);

    if (
      !dataWarehouses.loading &&
      dataWarehouses.data &&
      dataWarehouses.data.distributionCenters.length > 0
    ) {
      dataWarehouses.data.distributionCenters.map((item) => {
        const { id, name } = item;

        return setDistributionCenters((current) => [
          ...current,
          {
            id,
            name,
          },
        ]);
      });
    }
  }, [dataWarehouses.loading, dataWarehouses.data]);
  useEffect(() => {
    setSectors([]);

    if (
      !dataSectors.loading &&
      dataSectors.data &&
      dataSectors.data.sectors.length > 0
    ) {
      dataSectors.data.sectors.map((item) => {
        const { id, name, sectorPolygons } = item;

        return setSectors((current) => [
          ...current,
          {
            id,
            name,
            sectorPolygons,
          },
        ]);
      });
    }
  }, [dataSectors.loading, dataSectors.data]);

  useEffect(() => {
    setIdentificationClient([]);

    if (
      !dataClient.loading &&
      dataClient.data &&
      dataClient.data?.clients.length > 0
    ) {
      setIdentificationClient(dataClient.data?.clients);
    }
  }, [dataClient.loading, dataClient.data?.clients]);

  useEffect(() => {
    if (ordersLine.length > 0) setHasLineErrors(false);
    ordersLine.map((item) => {
      const numeric = new RegExp("^\\d+$");
      const { productNumber, productName, orderedQuantity } = item;

      if (numeric.test(orderedQuantity)) {
        if (orderedQuantity < 1) {
          return setHasLineErrors(true);
        }
      }

      if (productNumber.trim().length < 1) {
        return setHasLineErrors(true);
      }
      if (productName.trim().length < 1) {
        return setHasLineErrors(true);
      }
      return setHasLineErrors(false);
    });
  }, [ordersLine]);

  useEffect(() => {
    if (ordersLines.length > 0) setHasLineErrors(false);

    ordersLines.map((item) => {
      const numeric = new RegExp("^\\d+$");
      const { productNumber, productName, orderedQuantity } = item;

      if (numeric.test(orderedQuantity)) {
        if (orderedQuantity < 1) {
          return setHasLineErrors(true);
        }
      }

      if (productNumber.trim().length < 1) {
        return setHasLineErrors(true);
      }
      if (productName.trim().length < 1) {
        return setHasLineErrors(true);
      }
      return setHasLineErrors(false);
    });
  }, [ordersLines]);

  const handleNewClient = (e) => {
    setIsSelectActive(!e.target.checked);
  };

  const handleChange = async (value) => {
    const clientName = identificationClient.find((item) => item.nit === value);
    if (value != undefined && clientName) {
      form.setFieldsValue({
        custIdentificationNumber: value,
        custFullName: clientName.name,
        custPhoneNumber: clientName.clientAddresses[0]?.cellPhoneNumber,
        custEmail: clientName.email,

        address: clientName.clientAddresses[0]?.address,
        state: clientName.clientAddresses[0]?.state,
        city: clientName.clientAddresses[0]?.city,
        addressComplement: clientName.clientAddresses[0]?.addressComplement,
        destination: `${clientName.clientAddresses[0]?.lat}, ${clientName.clientAddresses[0]?.lng}`,
        sectorId: clientName.clientAddresses[0]?.sectorId,
        typePerson: clientName.typePerson,
      });
    } else if (value == undefined && !clientName) {
      form.setFieldsValue({
        custIdentificationNumber: "",
        custFullName: "",
        custPhoneNumber: "",
        custEmail: "",
        custPhoneNumber: "",
        address: "",
        state: "",
        city: "",
        addressComplement: "",
        destination: "",
        sectorId: "",
        typePerson: "",
      });
    }
    //const checkClient = await getCheckCliente({variables : {nit: form.getFieldValue("custIdentificationNumber")}});
    // console.log(checkClient.data.clients[0]?.nit);
  };

  const handleInsertOrderTableMemory = (values) => {
    const {
      orderNumber,
      address,
      addressComplement,
      sectorId,
      custIdentificationNumber,
      custFullName,
      custPhoneNumber,
      paymentMethod,
      totalOrderAmount,
      destination,
      height,
      width,
      depth,
      weight,
      custEmail,
      priority,
      city,
      state,
      notes,
      distributionCenterId,
      expectedDeliveryStartTime,
      expectedDeliveryEndTime,
      customerSupplierId,
      addressBookId,
      consecutiveBill,
      consecutiveRemission,
      consecutiveTRM,
      consecutiveSaleOrder,
      consecutiveShipping,
      consecutiveBurden,
      type,
      cubicMeters,
      orderedQuantity,
      packageId,
      packageContent,
      destinationDistributionCenterId,
      linesDetail,
      paymentOrCashOnDeliveryRequired,
      enableDeliveryWindow,
      totalCubicMeters,
      enablePackageDimensions,
      id,
    } = values;

    if (!isUpdate) {
      apolloClient
        .query({
          query: fetchOrderByOrderNumber,
          variables: { orderNumber },
          fetchPolicy: "no-cache",
        })
        .then(async ({ data }) => {
          if (data.ordersTable.length > 0) {
            setHasOrder(false);
            await secuenciaNumerica(true);
            Swal.fire({
              title: "Orden existe",
              text: `El número de orden '${orderNumber}' ya se encuentra registrado en el sistema. Verifique.`,
              icon: "error",
              confirmButtonText: "Cerrar",
            });
          } else if (formLinesDetail === false) {
            setHasOrder(false);
            setFormLinesDetail(false);
            setOrdersLine([]);
            setOrdersTable({
              orderNumber,
              address,
              addressComplement,
              sectorId,
              custIdentificationNumber,
              custFullName,
              custPhoneNumber,
              paymentMethod,
              totalOrderAmount,
              destination,
              height,
              width,
              depth,
              weight,
              custEmail,
              priority,
              city,
              state,
              notes,
              distributionCenterId,
              expectedDeliveryStartTime,
              expectedDeliveryEndTime,
              customerSupplierId,
              addressBookId,
              consecutiveBill,
              consecutiveRemission,
              consecutiveTRM,
              consecutiveSaleOrder,
              consecutiveShipping,
              consecutiveBurden,
              type,
              cubicMeters,
              orderedQuantity,
              packageId,
              packageContent,
              destinationDistributionCenterId,
              linesDetail,
              paymentOrCashOnDeliveryRequired,
              enableDeliveryWindow,
              totalCubicMeters,
              enablePackageDimensions,
              id,
            });
          } else {
            setHasOrder(true);
            setOrdersTable({
              orderNumber,
              address,
              addressComplement,
              sectorId,
              custIdentificationNumber,
              custFullName,
              custPhoneNumber,
              paymentMethod,
              totalOrderAmount,
              destination,
              height,
              width,
              depth,
              weight,
              custEmail,
              priority,
              city,
              state,
              notes,
              distributionCenterId,
              expectedDeliveryStartTime,
              expectedDeliveryEndTime,
              customerSupplierId,
              addressBookId,
              consecutiveBill,
              consecutiveRemission,
              consecutiveTRM,
              consecutiveSaleOrder,
              consecutiveShipping,
              consecutiveBurden,
              type,
              cubicMeters,
              orderedQuantity,
              packageId,
              packageContent,
              destinationDistributionCenterId,
              linesDetail,
              paymentOrCashOnDeliveryRequired,
              enableDeliveryWindow,
              totalCubicMeters,
              enablePackageDimensions,
              id,
            });
          }
        });
    } else {
      setHasOrder(true);
      setOrdersTable({
        orderNumber,
        address,
        addressComplement,
        sectorId,
        custIdentificationNumber,
        custFullName,
        custPhoneNumber,
        paymentMethod,
        totalOrderAmount,
        destination,
        height,
        width,
        depth,
        weight,
        custEmail,
        priority,
        city,
        state,
        notes,
        distributionCenterId,
        expectedDeliveryStartTime,
        expectedDeliveryEndTime,
        customerSupplierId,
        addressBookId,
        consecutiveBill,
        consecutiveRemission,
        consecutiveTRM,
        consecutiveSaleOrder,
        consecutiveShipping,
        consecutiveBurden,
        type,
        cubicMeters,
        orderedQuantity,
        packageId,
        packageContent,
        destinationDistributionCenterId,
        linesDetail,
        paymentOrCashOnDeliveryRequired,
        enableDeliveryWindow,
        totalCubicMeters,
        enablePackageDimensions,
        id,
      });
    }
  };

  const handleSave = async () => {
    let _sequenceOrderNumber;
    const allLinesFilled = ordersLine.every((line) => {
      return Boolean(line.productName) && Boolean(line.productNumber);
    });
    if (
      !form.getFieldValue("orderNumber") ||
      !form.getFieldValue("distributionCenterId") ||
      !form.getFieldValue("type") ||
      !form.getFieldValue("custFullName") ||
      !form.getFieldValue("address") ||
      !form.getFieldValue("destination") ||
      !form.getFieldValue("sectorId") ||
      !form.getFieldValue("priority") ||
      !form.getFieldValue("custIdentificationNumber") ||
      !form.getFieldValue("custPhoneNumber") ||
      !form.getFieldValue("typePerson")
    ) {
      Swal.fire({
        title: "Campos vacios",
        text: `Hace falta diligenciar campos, por favor fijarse en los campos con asteriscos rojos`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (!form.getFieldValue("editOrderNumber") && isUpdate === true) {
      Swal.fire({
        title: "Campos orden vacío",
        text: `Por favor elige una orden a editar más arriba`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      !form.getFieldValue("destinationDistributionCenterId") &&
      formType === "warehousesTransfer"
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Hace falta diligenciar el campo Cliente/Proveedor/Centro`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "delivery" &&
      !form.getFieldValue("consecutiveSaleOrder") &&
      !form.getFieldValue("consecutiveRemission") &&
      !form.getFieldValue("consecutiveBill")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar numero de factura o remisión o pedido de venta`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "pickup" &&
      !form.getFieldValue("consecutiveBill") &&
      !form.getFieldValue("consecutiveRemission")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de factura o remisión`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "warehousesTransfer" &&
      !form.getFieldValue("consecutiveBurden") &&
      !form.getFieldValue("consecutiveShipping") &&
      !form.getFieldValue("consecutiveTRM")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de traslado o número del envío o número de la carga`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formPaymentOrCashOnDeliveryRequired === true &&
      !form.getFieldValue("totalOrderAmount")
    ) {
      Swal.fire({
        title: "Campo de requiere cobro activo",
        text: "Se debe diligenciar el monto total",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryStartTime") &&
        !form.getFieldValue("expectedDeliveryEndTime")) ||
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryStartTime")) ||
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryEndTime"))
    ) {
      Swal.fire({
        title: "Compromiso de entrega activo",
        text: "Por favor elige una fecha de inicio a fin de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formEnablePackageDimensions === true &&
      !form.getFieldValue("packageId")
    ) {
      Swal.fire({
        title: "Tipo de paquete vacío",
        text: "Por favor ingrese el tipo de paquete de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (formLinesDetail === true && allLinesFilled === false) {
      Swal.fire({
        title: "Detalle de línea vacío",
        text: "Por favor ingrese el código y nombre del producto",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else {
      const {
        orderNumber,
        address,
        addressComplement,
        sectorId,
        custIdentificationNumber,
        custFullName,
        custPhoneNumber,
        paymentMethod,
        totalOrderAmount,
        destination,
        height,
        width,
        depth,
        weight,
        custEmail,
        priority,
        city,
        state,
        notes,
        distributionCenterId,
        expectedDeliveryStartTime,
        expectedDeliveryEndTime,
        customerSupplierId,
        addressBookId,
        consecutiveBill,
        consecutiveRemission,
        consecutiveTRM,
        consecutiveSaleOrder,
        consecutiveShipping,
        consecutiveBurden,
        type,
        cubicMeters,
        orderedQuantity,
        packageId,
        packageContent,
        destinationDistributionCenterId,
        linesDetail,
        paymentOrCashOnDeliveryRequired,
        enableDeliveryWindow,
        totalCubicMeters,
        enablePackageDimensions,
        id,
        typePerson,
      } = isUpdate ? form.getFieldsValue() : ordersTable;

      try {
        const checkClient = await getCheckCliente({
          variables: { nit: custIdentificationNumber },
        });
        if (checkClient.data.clients.length == 0) {
          await createClientNew({
            variables: {
              personType: "organization",
              person: "customer",
              documentType: "NIT",
              nit: custIdentificationNumber,
              name: custFullName,
              accountNumber: custIdentificationNumber,
              currency: "Pesos colombianos ",
              email: custEmail,
              enabled: true,
              companyId: companyId,
              typePerson: form.getFieldValue("typePerson"),
            },
          });
        } else {
          {
            await updateTypePersons({
              variables: {
                nit: custIdentificationNumber,
                typePerson: form.getFieldValue("typePerson"),
                email: custEmail,
              },
            });
          }
        }
        let res;
        if (isUpdate) {
          dataAddressBook.called &&
          dataAddressBook.data !== undefined &&
          dataAddressBook.data.clientAddresses.length > 0
            ? (res = await uptOrdersTable({
                variables: {
                  id,
                  orderNumber,
                  address,
                  addressComplement,
                  sectorId,
                  custIdentificationNumber,
                  custFullName,
                  custPhoneNumber,
                  paymentMethod,
                  totalOrderAmount,
                  destination,
                  height,
                  width,
                  depth,
                  weight,
                  custEmail,
                  priority,
                  city,
                  state,
                  notes,
                  distributionCenterId,
                  expectedDeliveryStartTime,
                  expectedDeliveryEndTime,
                  customerSupplierId,
                  addressBookId,
                  consecutiveBill,
                  consecutiveRemission,
                  consecutiveTRM,
                  consecutiveSaleOrder,
                  consecutiveShipping,
                  consecutiveBurden,
                  type,
                  cubicMeters,
                  orderedQuantity,
                  packageId,
                  packageContent,
                  destinationDistributionCenterId,
                  linesDetail,
                  paymentOrCashOnDeliveryRequired,
                  enableDeliveryWindow,
                  totalCubicMeters,
                  enablePackageDimensions,
                },
              }))
            : (res = await uptOrdersTable({
                variables: {
                  id,
                  orderNumber,
                  address,
                  addressComplement,
                  sectorId,
                  custIdentificationNumber,
                  custFullName,
                  custPhoneNumber,
                  paymentMethod,
                  totalOrderAmount,
                  destination,
                  height,
                  width,
                  depth,
                  weight,
                  custEmail,
                  priority,
                  city,
                  state,
                  notes,
                  distributionCenterId,
                  expectedDeliveryStartTime,
                  expectedDeliveryEndTime,
                  customerSupplierId,
                  consecutiveBill,
                  consecutiveRemission,
                  consecutiveTRM,
                  consecutiveSaleOrder,
                  consecutiveShipping,
                  consecutiveBurden,
                  type,
                  cubicMeters,
                  orderedQuantity,
                  packageId,
                  packageContent,
                  destinationDistributionCenterId,
                  linesDetail,
                  paymentOrCashOnDeliveryRequired,
                  enableDeliveryWindow,
                  totalCubicMeters,
                  enablePackageDimensions,
                },
              }));
          await apolloClient
            .mutate({
              mutation: deleteOrdersLine,
              variables: {
                deleteArray: ordersLine.map((item) => item.id),
              },
              fetchPolicy: "no-cache",
            })
            .then(() => {
              ordersLines.map((item) => {
                const { productNumber, productName, orderedQuantity } = item;

                return apolloClient
                  .mutate({
                    mutation: insertOrdersLine,
                    variables: {
                      orderNumber,
                      productNumber,
                      productName,
                      orderedQuantity,
                      initialQuantity: item.orderedQuantity,
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then(async () => {
                    await getOrderRunning({
                      variables: {
                        id,
                      },
                    });
                  });
              });
            });

          Swal.fire({
            title: "Orden Editada",
            text: "Orden Editada correctamente!",
            icon: "success",
            confirmButtonText: "Cerrar",
          });
          setHasOrder(false);
          setFormPaymentOrCashOnDeliveryRequiredd(true);
          setFormLinesDetail(false);
          setFormEnablePackageDimensions(false);
          setFormEnableDeliveryWindow(false);
          setFormPaymentOrCashOnDeliveryRequired(false);
        } else {
          dataAddressBook.called &&
          dataAddressBook.data !== undefined &&
          dataAddressBook.data.clientAddresses.length > 0
            ? (res = await insOrdersTable({
                variables: {
                  orderNumber,
                  address,
                  addressComplement,
                  sectorId,
                  custIdentificationNumber,
                  custFullName,
                  custPhoneNumber,
                  paymentMethod,
                  totalOrderAmount,
                  destination,
                  height,
                  width,
                  depth,
                  weight,
                  custEmail,
                  priority,
                  city,
                  state,
                  notes,
                  distributionCenterId,
                  companyId,
                  expectedDeliveryStartTime,
                  expectedDeliveryEndTime,
                  customerSupplierId,
                  addressBookId,
                  consecutiveBill,
                  consecutiveRemission,
                  consecutiveTRM,
                  consecutiveSaleOrder,
                  consecutiveShipping,
                  consecutiveBurden,
                  type,
                  cubicMeters,
                  orderedQuantity,
                  packageId,
                  packageContent,
                  destinationDistributionCenterId,
                  linesDetail,
                  paymentOrCashOnDeliveryRequired,
                  enableDeliveryWindow,
                  totalCubicMeters,
                  enablePackageDimensions,
                  createByUserId: userid,
                  typePerson: form.getFieldValue("typePerson"),
                  loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
                },
              }))
            : (res = await insOrdersTable({
                variables: {
                  orderNumber,
                  address,
                  addressComplement,
                  sectorId,
                  custIdentificationNumber,
                  custFullName,
                  custPhoneNumber,
                  paymentMethod,
                  totalOrderAmount,
                  destination,
                  height,
                  width,
                  depth,
                  weight,
                  custEmail,
                  priority,
                  city,
                  state,
                  notes,
                  distributionCenterId,
                  companyId,
                  expectedDeliveryStartTime,
                  expectedDeliveryEndTime,
                  customerSupplierId,
                  consecutiveBill,
                  consecutiveRemission,
                  consecutiveTRM,
                  consecutiveSaleOrder,
                  consecutiveShipping,
                  consecutiveBurden,
                  type,
                  cubicMeters,
                  orderedQuantity,
                  packageId,
                  packageContent,
                  destinationDistributionCenterId,
                  linesDetail,
                  paymentOrCashOnDeliveryRequired,
                  enableDeliveryWindow,
                  totalCubicMeters,
                  enablePackageDimensions,
                  createByUserId: userid,
                  typePerson: form.getFieldValue("typePerson"),
                  loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
                },
              }));

          if (res.data.insert_ordersTable.affected_rows > 0) {
            ordersLine.map((item) => {
              const { productNumber, productName, orderedQuantity } = item;
              return apolloClient
                .mutate({
                  mutation: insertOrdersLine,
                  variables: {
                    orderNumber,
                    productNumber,
                    productName,
                    orderedQuantity,
                    initialQuantity: item.orderedQuantity,
                  },
                  fetchPolicy: "no-cache",
                })
                .then(async ({ data }) => {
                  if (data.insert_ordersLine.affected_rows > 0) {
                    loggerCooltrack({
                      module: "DataUpload",
                      operation: "ManualUploadData",
                      target: res.data.insert_ordersTable.returning[0].id,
                    });
                    setFormSinLinesDetail(false);
                    setHasOrder(false);
                    setFormPaymentOrCashOnDeliveryRequiredd(true);
                    setFormLinesDetail(false);
                    setFormEnablePackageDimensions(false);
                    setFormEnableDeliveryWindow(false);
                    setFormPaymentOrCashOnDeliveryRequired(false);
                  }
                });
            });
            const usr = JSON.parse(sessionStorage.getItem("userData"));
            if (dataRoute.userId) {
              addRoute({
                variables: {
                  userId: dataRoute.userId,
                  batch: dataRoute.assignedRoutes[0].routeBatch.batch,
                  routeOrder: dataRoute.assignedRoutes.length + 1,
                  orderTableId: res.data.insert_ordersTable.returning[0].id,
                  createByUserId: usr.id,
                  orderInVehicleTempId: null,
                },
                fetchPolicy: "no-cache",
              });
            }

            Swal.fire({
              title: "Orden ingresada",
              text: `Orden número ${form.getFieldValue(
                "orderNumber"
              )} ingresada correctamente!`,
              icon: "success",
              confirmButtonText: "Cerrar",
            });
            loggerCooltrack({
              module: "DataUpload",
              operation: "ManualUploadData",
              target: res.data.insert_ordersTable.returning[0].id,
            });
            setFormSinLinesDetail(false);
            setHasOrder(false);
            _sequenceOrderNumber = await secuenciaNumerica(true);
            setFormPaymentOrCashOnDeliveryRequiredd(true);
            setFormLinesDetail(false);
            setFormEnablePackageDimensions(false);
            setFormEnableDeliveryWindow(false);
            setFormPaymentOrCashOnDeliveryRequired(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
      
      form.setFieldsValue({
        id: "",
        orderNumber: _sequenceOrderNumber,
        address: "",
        addressComplement: "",
        sectorId: "",
        sectorName: "",
        custIdentificationNumber: "",
        custFullName: "",
        custPhoneNumber: "",
        paymentMethod: "",
        totalOrderAmount: 0,
        destination: "",
        height: 0,
        width: 0,
        depth: 0,
        weight: 0,
        custEmail: "",
        priority: "",
        priorityName: "",
        routeRoutingId: "",
        routeRoutingName: "",
        orderRoutingId: "",
        orderRoutingName: "",
        city: "",
        state: "",
        notes: "",
        distributionCenterId: userDistributionCenterId,
        expectedDeliveryStartTime: "",
        expectedDeliveryEndTime: "",
        customerSupplierId: "",
        addressBookId:
          dataAddressBook.called &&
          dataAddressBook.data !== undefined &&
          dataAddressBook.data.clientAddresses.length > 0
            ? ""
            : null,
        consecutiveBill: "",
        consecutiveRemission: "",
        consecutiveTRM: "",
        consecutiveSaleOrder: "",
        consecutiveShipping: "",
        consecutiveBurden: "",
        type: orderTypes[0]?.value !== null ? orderTypes[0]?.label : "",
        cubicMeters: 0,
        orderedQuantity: 0,
        packageId:
          formEnablePackageDimensions === false || formLinesDetail === false
            ? null
            : "",
        packageContent: "",
        destinationDistributionCenterId: "",
        linesDetail: false,
        paymentOrCashOnDeliveryRequired: false,
        enableDeliveryWindow: false,
        totalCubicMeters: 0,
        enablePackageDimensions: false,
      });
      setFormLinesDetail(false);
      form.setFieldsValue({
        linesDetail: false,
      });
    }
  };

  const handleSaveNoLines = async () => {
    let _sequenceOrderNumber;
    const allLinesFilled = ordersLine.every((line) => {
      return Boolean(line.productName) && Boolean(line.productNumber);
    });
    if (
      !form.getFieldValue("orderNumber") ||
      !form.getFieldValue("distributionCenterId") ||
      !form.getFieldValue("type") ||
      !form.getFieldValue("custFullName") ||
      !form.getFieldValue("address") ||
      !form.getFieldValue("destination") ||
      !form.getFieldValue("sectorId") ||
      !form.getFieldValue("priority") ||
      !form.getFieldValue("custIdentificationNumber") ||
      !form.getFieldValue("custPhoneNumber") ||
      !form.getFieldValue("typePerson")
    ) {
      Swal.fire({
        title: "Campos vacios",
        text: `Hace falta diligenciar campos, por favor fijarse en los campos con asteriscos rojos`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (!form.getFieldValue("editOrderNumber") && isUpdate === true) {
      Swal.fire({
        title: "Campos orden vacío",
        text: `Por favor elige una orden a editar más arriba`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      !form.getFieldValue("destinationDistributionCenterId") &&
      formType === "warehousesTransfer"
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Hace falta diligenciar el campo Cliente/Proveedor/Centro`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "delivery" &&
      !form.getFieldValue("consecutiveSaleOrder") &&
      !form.getFieldValue("consecutiveRemission") &&
      !form.getFieldValue("consecutiveBill")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar numero de factura o remisión o pedido de venta`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "pickup" &&
      !form.getFieldValue("consecutiveBill") &&
      !form.getFieldValue("consecutiveRemission")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de factura o remisión`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "warehousesTransfer" &&
      !form.getFieldValue("consecutiveBurden") &&
      !form.getFieldValue("consecutiveShipping") &&
      !form.getFieldValue("consecutiveTRM")
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de traslado o número del envío o número de la carga`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formPaymentOrCashOnDeliveryRequired === true &&
      !form.getFieldValue("totalOrderAmount")
    ) {
      Swal.fire({
        title: "Campo de requiere cobro activo",
        text: "Se debe diligenciar el monto total",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryStartTime") &&
        !form.getFieldValue("expectedDeliveryEndTime")) ||
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryStartTime")) ||
      (formEnableDeliveryWindow === true &&
        !form.getFieldValue("expectedDeliveryEndTime"))
    ) {
      Swal.fire({
        title: "Compromiso de entrega activo",
        text: "Por favor elige una fecha de inicio a fin de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formEnablePackageDimensions === true &&
      !form.getFieldValue("packageId")
    ) {
      Swal.fire({
        title: "Tipo de paquete vacío",
        text: "Por favor ingrese el tipo de paquete de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (formLinesDetail === true && allLinesFilled === false) {
      Swal.fire({
        title: "Detalle de línea vacío",
        text: "Por favor ingrese el código y nombre del producto",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (!form.getFieldValue("typePerson")) {
      Swal.fire({
        title: "Tipo de persona",
        text: "Por favor ingrese el tipo de persona",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else {
      setFormSinLinesDetail(true);
      const {
        id,
        orderNumber,
        address,
        addressComplement,
        sectorId,
        custIdentificationNumber,
        custFullName,
        custPhoneNumber,
        paymentMethod,
        totalOrderAmount,
        destination,
        height,
        width,
        depth,
        weight,
        custEmail,
        priority,
        city,
        state,
        notes,
        distributionCenterId,
        expectedDeliveryStartTime,
        expectedDeliveryEndTime,
        customerSupplierId,
        addressBookId,
        consecutiveBill,
        consecutiveRemission,
        consecutiveTRM,
        consecutiveSaleOrder,
        consecutiveShipping,
        consecutiveBurden,
        type,
        cubicMeters,
        orderedQuantity,
        packageId,
        packageContent,
        destinationDistributionCenterId,
        linesDetail,
        paymentOrCashOnDeliveryRequired,
        enableDeliveryWindow,
        totalCubicMeters,
        enablePackageDimensions,
        typePerson,
      } = isUpdate ? form.getFieldsValue() : ordersTable;

      try {
        const checkClient = await getCheckCliente({
          variables: { nit: custIdentificationNumber },
        });
        if (checkClient.data.clients.length == 0) {
          await createClientNew({
            variables: {
              personType: "organization",
              person: "customer",
              documentType: "NIT",
              nit: custIdentificationNumber,
              name: custFullName,
              accountNumber: custIdentificationNumber,
              currency: "Pesos colombianos ",
              email: custEmail,
              enabled: true,
              companyId: companyId,
              typePerson: form.getFieldValue("typePerson"),
            },
          });
        } else {
          {
            await updateTypePersons({
              variables: {
                nit: custIdentificationNumber,
                typePerson: form.getFieldValue("typePerson"),
                email: custEmail,
              },
            });
          }
        }
        if (isUpdate) {
          dataAddressBook.called &&
          dataAddressBook.data !== undefined &&
          dataAddressBook.data.clientAddresses.length > 0
            ? await uptOrdersTable({
                variables: {
                  address,
                  addressComplement,
                  custEmail,
                  custFullName,
                  custIdentificationNumber,
                  custPhoneNumber,
                  destination,
                  id,
                  orderNumber,
                  paymentMethod,
                  priority,
                  sectorId,
                  totalOrderAmount,
                  weight: 0,
                },
              }).then(async ({ data }) => {
                await getOrderRunning({
                  variables: {
                    id,
                  },
                });
                if (data.update_ordersTable.affected_rows > 0) {
                  Swal.fire({
                    title: "Orden Editada",
                    text: "Orden Editada correctamente!",
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });
                  setHasOrder(false);
                  setFormPaymentOrCashOnDeliveryRequiredd(true);
                  setFormLinesDetail(false);
                  setFormEnablePackageDimensions(false);
                  setFormEnableDeliveryWindow(false);
                  setFormPaymentOrCashOnDeliveryRequired(false);
                }
              })
            : await uptOrdersTable({
                variables: {
                  address,
                  addressComplement,
                  custEmail,
                  custFullName,
                  custIdentificationNumber,
                  custPhoneNumber,
                  destination,
                  id,
                  orderNumber,
                  paymentMethod,
                  priority,
                  sectorId,
                  totalOrderAmount,
                  weight: 0,
                },
              }).then(async ({ data }) => {
                await getOrderRunning({
                  variables: {
                    id,
                  },
                });
                if (data.update_ordersTable.affected_rows > 0) {
                  Swal.fire({
                    title: "Orden Editada",
                    text: "Orden Editada correctamente!",
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });
                  setHasOrder(false);
                  setFormPaymentOrCashOnDeliveryRequiredd(true);
                  setFormLinesDetail(false);
                  setFormEnablePackageDimensions(false);
                  setFormEnableDeliveryWindow(false);
                  setFormPaymentOrCashOnDeliveryRequired(false);
                }
              });
        } else {
          dataAddressBook.called &&
          dataAddressBook.data !== undefined &&
          dataAddressBook.data.clientAddresses.length > 0
            ? await instOrdersTableNoLines({
                variables: {
                  orderNumber,
                  address,
                  addressComplement,
                  sectorId,
                  custIdentificationNumber,
                  custFullName,
                  custPhoneNumber,
                  paymentMethod,
                  totalOrderAmount,
                  destination,
                  height,
                  width,
                  depth,
                  weight,
                  custEmail,
                  priority,
                  city,
                  state,
                  notes,
                  distributionCenterId,
                  companyId,
                  expectedDeliveryStartTime,
                  expectedDeliveryEndTime,
                  customerSupplierId,
                  addressBookId,
                  consecutiveBill,
                  consecutiveRemission,
                  consecutiveTRM,
                  consecutiveSaleOrder,
                  consecutiveShipping,
                  consecutiveBurden,
                  type,
                  cubicMeters,
                  orderedQuantity,
                  packageId,
                  packageContent,
                  destinationDistributionCenterId,
                  linesDetail,
                  paymentOrCashOnDeliveryRequired,
                  enableDeliveryWindow,
                  totalCubicMeters,
                  enablePackageDimensions,
                  createByUserId: userid,
                  typePerson: form.getFieldValue("typePerson"),
                  loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
                },
              }).then(async ({ data }) => {
                console.log(data);
                if (data.insert_ordersTable.affected_rows > 0) {
                  Swal.fire({
                    title: "Orden ingresada",
                    text: `Orden número ${form.getFieldValue(
                      "orderNumber"
                    )} ingresada correctamente!`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });
                  loggerCooltrack({
                    module: "DataUpload",
                    operation: "ManualUploadData",
                    target: data.insert_ordersTable.returning[0].id,
                  });
                  setFormSinLinesDetail(false);
                  setHasOrder(false);
                  _sequenceOrderNumber = await secuenciaNumerica(true);
                  setFormPaymentOrCashOnDeliveryRequiredd(true);
                  setFormLinesDetail(false);
                  setFormEnablePackageDimensions(false);
                  setFormEnableDeliveryWindow(false);
                  setFormPaymentOrCashOnDeliveryRequired(false);
                }
              })
            : await instOrdersTableNoLines({
                variables: {
                  orderNumber,
                  address,
                  addressComplement,
                  sectorId,
                  custIdentificationNumber,
                  custFullName,
                  custPhoneNumber,
                  paymentMethod,
                  totalOrderAmount,
                  destination,
                  height,
                  width,
                  depth,
                  weight,
                  custEmail,
                  priority,
                  city,
                  state,
                  notes,
                  distributionCenterId,
                  companyId,
                  expectedDeliveryStartTime,
                  expectedDeliveryEndTime,
                  customerSupplierId,
                  consecutiveBill,
                  consecutiveRemission,
                  consecutiveTRM,
                  consecutiveSaleOrder,
                  consecutiveShipping,
                  consecutiveBurden,
                  type,
                  cubicMeters,
                  orderedQuantity,
                  packageId,
                  packageContent,
                  destinationDistributionCenterId,
                  linesDetail,
                  paymentOrCashOnDeliveryRequired,
                  enableDeliveryWindow,
                  totalCubicMeters,
                  enablePackageDimensions,
                  createByUserId: userid,
                  typePerson: form.getFieldValue("typePerson"),
                  loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
                },
              }).then(async ({ data }) => {
                if (data.insert_ordersTable.affected_rows > 0) {
                  Swal.fire({
                    title: "Orden ingresada",
                    text: `Orden número ${form.getFieldValue(
                      "orderNumber"
                    )} ingresada correctamente!`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });
                  loggerCooltrack({
                    module: "DataUpload",
                    operation: "ManualUploadData",
                    target: data.insert_ordersTable.returning[0].id,
                  });
                  setFormSinLinesDetail(false);
                  setHasOrder(false);
                  _sequenceOrderNumber = await secuenciaNumerica(true);
                  setFormPaymentOrCashOnDeliveryRequiredd(true);
                  setFormLinesDetail(false);
                  setFormEnablePackageDimensions(false);
                  setFormEnableDeliveryWindow(false);
                  setFormPaymentOrCashOnDeliveryRequired(false);
                }
              });
        }
      } catch (error) {
        console.error(error);
      }

      form.setFieldsValue({
        id: "",
        orderNumber: _sequenceOrderNumber,
        address: "",
        addressComplement: "",
        sectorId: "",
        sectorName: "",
        custIdentificationNumber: "",
        custFullName: "",
        custPhoneNumber: "",
        paymentMethod: "",
        totalOrderAmount: 0,
        destination: "",
        height: 0,
        width: 0,
        depth: 0,
        weight: 0,
        custEmail: "",
        priority: "",
        priorityName: "",
        routeRoutingId: "",
        routeRoutingName: "",
        orderRoutingId: "",
        orderRoutingName: "",
        city: "",
        state: "",
        notes: "",
        distributionCenterId: userDistributionCenterId,
        expectedDeliveryStartTime: "",
        expectedDeliveryEndTime: "",
        customerSupplierId: "",
        addressBookId:
          dataAddressBook.called &&
          dataAddressBook.data !== undefined &&
          dataAddressBook.data.clientAddresses.length > 0
            ? ""
            : null,
        consecutiveBill: "",
        consecutiveRemission: "",
        consecutiveTRM: "",
        consecutiveSaleOrder: "",
        consecutiveShipping: "",
        consecutiveBurden: "",
        type: orderTypes[0]?.value !== null ? orderTypes[0]?.label : "",
        cubicMeters: 0,
        orderedQuantity: 0,
        packageId: "",
        packageContent: "",
        destinationDistributionCenterId: "",
        linesDetail: false,
        paymentOrCashOnDeliveryRequired: false,
        enableDeliveryWindow: false,
        totalCubicMeters: 0,
        enablePackageDimensions: false,
        
      });
      setFormLinesDetail(false);
      form.setFieldsValue({
        linesDetail: false,
      });
    }
  };

  const handleClear = async () => {
    setHasOrder(false);
    form.setFieldsValue({
      id: "",
      orderNumber: sequenceOrderNumber,
      address: "",
      addressComplement: "",
      sectorId: "",
      sectorName: "",
      custIdentificationNumber: "",
      custFullName: "",
      custPhoneNumber: "",
      paymentMethod: "",
      totalOrderAmount: 0,
      destination: "",
      height: 0,
      width: 0,
      depth: 0,
      weight: 0,
      custEmail: "",
      priority: "",
      priorityName: "",
      routeRoutingId: "",
      routeRoutingName: "",
      orderRoutingId: "",
      orderRoutingName: "",
      city: "",
      state: "",
      notes: "",
      distributionCenterId: userDistributionCenterId,
      expectedDeliveryStartTime: "",
      expectedDeliveryEndTime: "",
      customerSupplierId: "",
      addressBookId: "",
      consecutiveBill: "",
      consecutiveRemission: "",
      consecutiveTRM: "",
      consecutiveSaleOrder: "",
      consecutiveShipping: "",
      consecutiveBurden: "",
      type: orderTypes[0]?.value !== null ? orderTypes[0]?.label : "",
      cubicMeters: 0,
      orderedQuantity: 0,
      packageId: "",
      packageContent: "",
      destinationDistributionCenterId: "",
      linesDetail: false,
      paymentOrCashOnDeliveryRequired: false,
      enableDeliveryWindow: false,
      totalCubicMeters: 0,
      enablePackageDimensions: false,
      typePerson: "",
    });
  };
  const handleClearLine = () => {
    setOrdersLine([]);
  };

  const isRequired = (fieldName) => {
    if (
      dataFields.called &&
      dataFields.data &&
      dataFields.data.uploadDataTableController.length > 0
    ) {
      const field =
        dataFields.data.uploadDataTableController[0].uploadDataFields.filter(
          (item) => item.fieldName === fieldName
        );

      if (field.length > 0) {
        return field[0].mandatory;
      } else {
        return false;
      }
    }

    return false;
  };

  const validateField = (fieldName, objValue) => {
    if (
      dataFields.called &&
      dataFields.data &&
      dataFields.data.uploadDataTableController.length > 0
    ) {
      const field =
        dataFields.data.uploadDataTableController[0].uploadDataFields.filter(
          (item) => item.fieldName === fieldName
        );

      if (field.length > 0) {
        const def = field[0];
        const textToValidate = new RegExp(/^[a-zA-Z\s]*$/);
        const numberToValidate = new RegExp("^[0-9]+$");

        if (def.dataType === "OnlyText" && !textToValidate.test(objValue)) {
          return `Error: El valor '${objValue}' no es correcto, deben ser solo létras.`;
        } else if (
          def.dataType === "OnlyNumbers" &&
          !numberToValidate.test(objValue)
        ) {
          return `Error: El valor '${objValue}' no es un número válido.`;
        } else if (
          def.dataType === "OnlyNumbers" &&
          def.minValue !== null &&
          objValue.length < def.minValue
        ) {
          return `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;
        } else if (
          def.dataType === "OnlyNumbers" &&
          def.maxValue !== null &&
          objValue.length > def.maxValue
        ) {
          return `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;
        } else if (objValue === "" && def.mandatory) {
          return "Error: Obligatorio";
        } else if (objValue === "" && !def.mandatory) {
          return "";
        } else if (def.dataType === "Number" && isNaN(objValue)) {
          return `Error: El valor '${objValue}' no es un número válido.`;
        } else if (def.dataType === "LatLng" && !isValidLatLng(objValue)) {
          return `Error: El valor '${objValue}' no es una latitud/longitud válida.`;
        } else if (
          def.dataType === "Text" &&
          def.minValue !== null &&
          objValue.length < def.minValue
        ) {
          return `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;
        } else if (
          def.dataType === "Text" &&
          def.maxValue !== null &&
          objValue.length > def.maxValue
        ) {
          return `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;
        } else if (
          def.dataType === "Number" &&
          def.minValue !== 0 &&
          objValue < def.minValue
        ) {
          return `Error: El valor (${objValue}) no puede ser menor que '${def.minValue}'.`;
        } else if (
          def.dataType === "Number" &&
          def.maxValue !== null &&
          objValue > def.maxValue
        ) {
          return `Error: El valor (${objValue}) no puede ser mayor que '${def.maxValue}'.`;
        } else {
          return "";
        }
      } else {
        return `Error: Campo '${fieldName}' no existe.`;
      }
    }
    return "Error: Error no controlado.";
  };
  const destinarionAutoComplet = async (_address, _address1, _address2) => {
    const addressDepart = _address2;
    const addressCity = _address1;
    const addressA = _address;
    const addressComplement = `${addressDepart},${addressCity},${addressA}`;
    if (_address.length > 0) {
      // verificar si no viene la latitud, longitud,
      // y si viene la dirección, convertirla a lat, lng
      let newAddress = "";
      const address = addressComplement
        .replace(",", " ")
        .replace(/\s\s+/g, " ")
        .replace("#", "")
        .split(" ");

      if (address.length > 0) {
        address.forEach((item) => {
          newAddress += item + "+";
        });
        newAddress = newAddress.substring(0, newAddress.length - 1);

        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
        );

        if (res.status === 200) {
          form.setFieldsValue({
            destination: `${res.data.results[0].geometry.location.lat},${res.data.results[0].geometry.location.lng}`,
          });
        }
        let sector = calculateGeoEnclosure(
          {
            lat: res.data.results[0].geometry.location.lat,
            lng: res.data.results[0].geometry.location.lng,
          },
          sectors
        );
        setGeoClouser(sector);
      }
    }
  };

  const history = useHistory();
  const Click = () => {
    history.replace("/main-upload");
  };

  const cargarDataOrder = (dataOrderRunningCurrent) => {
    console.log(dataOrderRunningCurrent);
    form.setFieldsValue({
      id: dataOrderRunningCurrent.id,
      orderNumber: dataOrderRunningCurrent.orderNumber,
      address: dataOrderRunningCurrent.address,
      addressComplement: dataOrderRunningCurrent.addressComplement,
      sectorId: dataOrderRunningCurrent.sectorId,
      sectorName: dataOrderRunningCurrent.sector.name,
      custIdentificationNumber:
        dataOrderRunningCurrent.custIdentificationNumber,
      custFullName: dataOrderRunningCurrent.custFullName,
      custPhoneNumber: dataOrderRunningCurrent.custPhoneNumber,
      paymentOrCashOnDeliveryRequired:
        dataOrderRunningCurrent.paymentMethod != null ? true : false,
      paymentMethod:
        dataOrderRunningCurrent.paymentMethod === null
          ? ""
          : dataOrderRunningCurrent.paymentMethod,
      totalOrderAmount:
        dataOrderRunningCurrent.totalOrderAmount === null
          ? 0
          : dataOrderRunningCurrent.totalOrderAmount,
      destination: dataOrderRunningCurrent.destination,
      height: dataOrderRunningCurrent.height,
      width: dataOrderRunningCurrent.width,
      depth: dataOrderRunningCurrent.depth,
      weight: dataOrderRunningCurrent.weight,
      custEmail: dataOrderRunningCurrent.custEmail,
      priority: dataOrderRunningCurrent.priority,
      priorityName: "",
      routeRoutingId: "",
      routeRoutingName: "",
      orderRoutingId: "",
      orderRoutingName: "",
      city: dataOrderRunningCurrent.city,
      state: dataOrderRunningCurrent.state,
      notes: dataOrderRunningCurrent.notes,
      distributionCenterId: dataOrderRunningCurrent.distributionCenterId,
      customerSupplierId: dataOrderRunningCurrent.customerSupplierId,
      consecutiveBill: dataOrderRunningCurrent.consecutiveBill,
      consecutiveRemission: dataOrderRunningCurrent.consecutiveRemission,
      consecutiveTRM: dataOrderRunningCurrent.consecutiveTRM,
      consecutiveSaleOrder: dataOrderRunningCurrent.consecutiveSaleOrder,
      consecutiveShipping: dataOrderRunningCurrent.consecutiveShipping,
      consecutiveBurden: dataOrderRunningCurrent.consecutiveBurden,
      type: dataOrderRunningCurrent.type,
      cubicMeters: dataOrderRunningCurrent.cubicMeters,
      orderedQuantity: dataOrderRunningCurrent.orderedQuantity,
      packageId: dataOrderRunningCurrent.packageId,
      packageContent: dataOrderRunningCurrent.packageContent,
      destinationDistributionCenterId:
        dataOrderRunningCurrent.destinationDistributionCenterId,
      enableDeliveryWindow: dataOrderRunningCurrent.enableDeliveryWindow,
      totalCubicMeters: dataOrderRunningCurrent.totalCubicMeters,
      enablePackageDimensions: dataOrderRunningCurrent.enablePackageDimensions === true ? setFormEnablePackageDimensions(true) : setFormEnablePackageDimensions(false),
      linesDetail: dataOrderRunningCurrent.linesDetail === true ? setFormLinesDetail(true) : setFormLinesDetail(false)
    });
  };
  
  const updateCentroDistribution = (_formCentroDistribution) => {
    if (_formCentroDistribution) {
      form.setFieldsValue({
        state: _formCentroDistribution.state,
        city: _formCentroDistribution.city,
        address: _formCentroDistribution.address,
        addressComplement: _formCentroDistribution.addressComplement,
        destination: `${_formCentroDistribution.lat},${_formCentroDistribution.lng}`,
        custFullName: _formCentroDistribution.contactName,
        custIdentificationNumber: _formCentroDistribution.name,
        custPhoneNumber: _formCentroDistribution.phone,
        custEmail: _formCentroDistribution.email,
        sectorId: _formCentroDistribution.sectorId,
        priority: "Alto",
        notes: "",
        addressBookId: "",
      });
    }
  };
  const updateAddressBook = (_formAddressBook) => {
    if (_formAddressBook) {
      form.setFieldsValue({
        address: _formAddressBook.address,
        addressComplement: _formAddressBook.addressComplement,
        sectorId: _formAddressBook.sectorId,
        sectorName:
          dataSectors.data.sectors.filter(
            (item) => item.id === _formAddressBook.sectorId
          ).length > 0
            ? dataSectors.data.sectors.filter(
                (item) => item.id === _formAddressBook.sectorId
              )[0].name
            : 0,
        custIdentificationNumber:
          formCustomer.nit === undefined ? customerSupplier : formCustomer.nit,
        custFullName: _formAddressBook.contactName,
        custPhoneNumber: _formAddressBook.cellPhoneNumber,
        paymentMethod: _formAddressBook.defaultPaymentMethod,
        destination: `${_formAddressBook.lat},${_formAddressBook.lng}`,
        custEmail: _formAddressBook.mail,
        priority: _formAddressBook.defaultPriority,
        priorityName:
          _formAddressBook.defaultPriority === "a_high"
            ? "Alto"
            : _formAddressBook.defaultPriority === "b_medium"
            ? "Medio"
            : "Bajo",
        city: _formAddressBook.city,
        state: _formAddressBook.state,
        notes: _formAddressBook.note,
      });
    }
    let sector = calculateGeoEnclosure(
      {
        lat: _formAddressBook.lat,
        lng: _formAddressBook.lng,
      },
      sectors
    );
    setGeoClouser(sector);
    setSelectedSector(
      sectors.find((item) => item.id === _formAddressBook.sectorId)?.name
    );
  };

  const funOrdeNumberEdit = async () => {
    const responseOrdeNumberEdit = await getOrdeNumberEdit();
    setDataOrdenNumEdit(responseOrdeNumberEdit.data.ordersTable);
  };

  const funCargaDataEdit = async (value) => {
    const cargaDataEdit = await getOrderRunning({
      variables: {
        id: value,
      },
    });

    setOrdersLines(
      cargaDataEdit.data.ordersTable.filter((item) => item.id === value)[0]
        .ordersLines
    );

    setOrdersLine(
      cargaDataEdit.data.ordersTable.filter((item) => item.id === value)[0]
        .ordersLines
    );

    cargarDataOrder(
      cargaDataEdit.data.ordersTable.filter((item) => item.id === value)[0]
    );
  };

  const updatePackages = (_formPackage) => {
    if (_formPackage) {
      form.setFieldsValue({
        packageContent: _formPackage.defaultItemName,
        cubicMeters: _formPackage.cubicMeters,
        height: _formPackage.height,
        width: _formPackage.width,
        depth: _formPackage.depth,
        weight: _formPackage.defaultWeight,
      });
    }
  };

  const updateTotalCubicMeter = (orderedQuantity) => {
    if (orderedQuantity && formPackage) {
      form.setFieldsValue({
        totalCubicMeters: formPackage.cubicMeters * orderedQuantity,
        weight: formPackage.defaultWeight * orderedQuantity,
      });
    }
  };
  const handleNew = () => {
    setShowDrawer(true);
  };
  const handleNewAddress = () => {
    apolloClient
      .query({
        query: fetchClientAddresess,
        variables: {
          clientId: formCustomer.id,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data, loading }) => {
        setClientAddresses([]);

        if (!loading && data.clientAddresses.length > 0) {
          const records = [];
          data.clientAddresses.map((item) => {
            const {
              id,
              name,
              contactName,
              phoneNumber,
              cellPhoneNumber,
              state,
              city,
              address,
              addressComplement,
              mail,
              principal,
              defaultDistributionCenterId,
              distributionCenterPrincipal,
              note,
              defaultPaymentMethod,
              defaultPriority,
              sectorId,
              country,
              lat,
              lng,
              enabled,
              clientId,
            } = item;

            const sector = item.clientAddressesSector
              ? item.clientAddressesSector.name
              : "";
            const distributionCenter = item.clientAddressesDistributionCenter
              ? item.clientAddressesDistributionCenter.name
              : "";

            const priorityArr = priorityList.filter(
              (item) => item.value === defaultPriority
            );
            const priority = priorityArr.length > 0 ? priorityArr[0].label : "";

            return records.push({
              id,
              name,
              contactName,
              phoneNumber,
              cellPhoneNumber,
              state,
              city,
              address,
              addressComplement,
              mail,
              principal,
              distributionCenter,
              defaultDistributionCenterId,
              distributionCenterPrincipal,
              note,
              defaultPaymentMethod,
              defaultPriority,
              priority,
              sector,
              sectorId,
              country,
              lat,
              lng,
              enabled,
              clientId,
            });
          });
          setClientAddresses(records);
        }
        setShowDrawerAddress(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="children-inner-container-with-bg">
      <Button
        style={{ marginTop: 10 }}
        type="primary"
        shape="round"
        icon={<ArrowLeftOutlined />}
        onClick={Click}
      ></Button>

      <Button
        className={"Editar_Orden_HeaderUploadScreen"}
        type="primary"
        ghost={!isUpdate}
        shape="round"
        icon={<FormOutlined />}
        disabled={role === "user" ? true : false}
        onClick={() => {
          setIsUpdate(!isUpdate);
        }}
      ></Button>

      <br />
      <br />

      <Form
        form={form}
        name="configuration"
        onFinish={handleInsertOrderTableMemory}
        initialValues={async () => await secuenciaNumerica(false)}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Card title="Información de la orden" size="small">
            <Descriptions
              style={{ marginBottom: "10px" }}
              layout="vertical"
              size="small"
              column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
              {isUpdate ? (
                <Descriptions.Item label="Editar Orden">
                  <Form.Item
                    name="editOrderNumber"
                    rules={[
                      {
                        required: true,
                        message: "Error: es obligatorio",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione..."
                      allowClear
                      onChange={(value, object) => {
                        form.setFieldsValue({
                          orderRoutingId: value,
                          orderRoutingName: object.children,
                        });
                        funCargaDataEdit(value);
                      }}
                      showSearch
                      className="form-element"
                      optionFilterProp="children"
                    >
                      {dataOrdenNumEdit.map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {" "}
                            {item.orderNumber}{" "}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item label="Número de orden">
                <label className="asterisco">*</label>
                <Form.Item
                  name="orderNumber"
                  className="form-element"
                  rules={[
                    {
                      required: isRequired("custIdentificationNumber"),
                      message: "Error: es obligatorio",
                    },
                    { required: isRequired("orderNumber"), message: "" },
                    ({ _, __ }) => ({
                      validator(obj, value) {
                        setHasOrder(false);
                        const res = validateField("orderNumber", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  formType === "pickup"
                    ? "Centro de acopio"
                    : "Centro de distribución"
                }
              >
                {" "}
                <label className="asterisco">*</label>
                <Form.Item
                  name="distributionCenterId"
                  rules={[{ required: true, message: "Error: es obligatorio" }]}
                >
                  <Select
                    allowClear
                    placeholder="Seleccione..."
                    className="form-element"
                    disabled={isUpdate ? true : false}
                    onChange={(value, object) => {
                      setFormDistributionCenter(
                        dataWarehouses.data.distributionCenters.filter(
                          (item) => item.id === value
                        )[0]
                      );
                      form.setFieldsValue({
                        distributionCenterId: value,
                        distributionCenterName: object?.children,
                      });
                    }}
                    showSearch
                    style={{
                      width: 200,
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {dataWarehouses.called &&
                      dataWarehouses.data !== undefined &&
                      dataWarehouses.data.distributionCenters.map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Tipo">
                <label className="asterisco">*</label>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: "Error: es obligatorio" }]}
                >
                  <Select
                    allowClear
                    placeholder="Seleccione..."
                    className="form-element"
                    disabled={isUpdate ? true : false}
                    onChange={(value, object) => {
                      setFormType(value);
                      form.setFieldsValue({
                        type: value,
                        typeName: object ? object.children : "",
                      });
                      if (formType !== "delivery" || formType !== "pickup") {
                        ResetForm();
                        form.setFieldsValue({
                          customerSupplierId: "",
                        });
                      }
                      if (formType === "warehousesTransfer") {
                        ResetForm();
                        form.setFieldsValue({
                          destinationDistributionCenterId: "",
                        });
                      }
                    }}
                    showSearch
                    style={{
                      width: 200,
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {orderTypes.map((item) => {
                      return (
                        <Select.Option
                          key={item.id}
                          value={item.value}
                          //disabled={
                          //  item.value === "warehousesTransfer" ||
                          //  item.value === "pickup" ||
                          //  item.value === "delivery"
                          //}
                        >
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              {formType === "pickup" && (
                <Descriptions.Item label="Cliente/Proveedor/Centro">
                  <label className="asterisco">*</label>
                  <Form.Item
                    name="customerSupplierId"
                    rules={[
                      { required: true, message: "Error: es obligatorio" },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione..."
                      allowClear
                      className="form-element-with-button"
                      disabled={isUpdate ? true : false}
                      defaultValue={form.getFieldValue("customerSupplierId")}
                      onChange={(value, object) => {
                        setFormCustomer(
                          dataCustomers.data.clients.filter(
                            (item) => item.id === value
                          )[0]
                        );
                        updateCentroDistribution(
                          dataWarehouses.data.distributionCenters.filter(
                            (item) => item.id === value
                          )[0]
                        );

                        form.setFieldsValue({
                          customerSupplierId: value,
                          customerSupplierName: object ? object.children : "",
                        });
                        setSelectedAddress(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      style={{
                        width: 200,
                      }}
                    >
                      {dataCustomers.called &&
                        dataCustomers.data !== undefined &&
                        dataCustomers.data.clients
                          .reduce((groups, item) => {
                            const groupLabel =
                              item.person === "customer"
                                ? "Clientes"
                                : item.person === "supplier"
                                ? "Proveedores"
                                : "";
                            const groupIndex = groups.findIndex(
                              (group) => group.label === groupLabel
                            );
                            if (groupIndex !== -1) {
                              groups[groupIndex].options.push(
                                <Select.Option
                                  value={item.id}
                                >{`${item.name} (${item.documentType} ${item.nit})`}</Select.Option>
                              );
                            } else {
                              groups.push({
                                label: groupLabel,
                                options: [
                                  <Select.Option
                                    value={item.id}
                                  >{`${item.name} (${item.documentType} ${item.nit})`}</Select.Option>,
                                ],
                              });
                            }
                            return groups;
                          }, [])
                          .map((group, index) => (
                            <Select.OptGroup label={group.label}>
                              {group.options}
                            </Select.OptGroup>
                          ))}
                      {dataWarehouses.called &&
                        dataWarehouses.data !== undefined &&
                        dataWarehouses.data.distributionCenters
                          .reduce((groups, item) => {
                            const groupLabel = "Centro";
                            const groupIndex = groups.findIndex(
                              (group) => group.label === groupLabel
                            );
                            if (groupIndex !== -1) {
                              groups[groupIndex].options.push(
                                <Select.Option value={item.id}>
                                  {item.name}
                                </Select.Option>
                              );
                            } else {
                              groups.push({
                                label: groupLabel,
                                options: [
                                  <Select.Option value={item.id}>
                                    {item.name}
                                  </Select.Option>,
                                ],
                              });
                            }
                            return groups;
                          }, [])
                          .map((group, index) => (
                            <Select.OptGroup label={group.label}>
                              {group.options}
                            </Select.OptGroup>
                          ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      ghost={!isUpdate}
                      style={{ width: "2.5vw" }} //referencia
                      icon={<UserAddOutlined />}
                      onClick={() => handleNew()}
                    ></Button>
                  </Form.Item>
                </Descriptions.Item>
              )}
              {formType === "delivery" && (
                <Descriptions.Item label="Cliente/Proveedor/Centro">
                  <label className="asterisco">*</label>
                  <Form.Item
                    name="customerSupplierId"
                    rules={[
                      { required: true, message: "Error: es obligatorio" },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione..."
                      allowClear
                      className="form-element-with-button"
                      disabled={isUpdate ? true : false}
                      onChange={(value, object) => {
                        setFormCustomer(
                          dataCustomers.data.clients.filter(
                            (item) => item.id === value
                          )[0]
                        );
                        updateCentroDistribution(
                          dataWarehouses.data.distributionCenters.filter(
                            (item) => item.id === value
                          )[0]
                        );

                        form.setFieldsValue({
                          customerSupplierId: value,
                          customerSupplierName: object ? object.children : "",
                        });
                        setSelectedAddress(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      style={{
                        width: 200,
                      }}
                    >
                      {dataCustomers.called &&
                        dataCustomers.data !== undefined &&
                        dataCustomers.data.clients
                          .reduce((groups, item) => {
                            const groupLabel =
                              item.person === "customer"
                                ? "Clientes"
                                : item.person === "supplier"
                                ? "Proveedores"
                                : "";
                            const groupIndex = groups.findIndex(
                              (group) => group.label === groupLabel
                            );
                            if (groupIndex !== -1) {
                              groups[groupIndex].options.push(
                                <Select.Option
                                  value={item.id}
                                >{`${item.name} (${item.documentType} ${item.nit})`}</Select.Option>
                              );
                            } else {
                              groups.push({
                                label: groupLabel,
                                options: [
                                  <Select.Option
                                    value={item.id}
                                  >{`${item.name} (${item.documentType} ${item.nit})`}</Select.Option>,
                                ],
                              });
                            }
                            return groups;
                          }, [])
                          .map((group, index) => (
                            <Select.OptGroup label={group.label}>
                              {group.options}
                            </Select.OptGroup>
                          ))}
                      {dataWarehouses.called &&
                        dataWarehouses.data !== undefined &&
                        dataWarehouses.data.distributionCenters
                          .reduce((groups, item) => {
                            const groupLabel = "Centro";
                            const groupIndex = groups.findIndex(
                              (group) => group.label === groupLabel
                            );
                            if (groupIndex !== -1) {
                              groups[groupIndex].options.push(
                                <Select.Option value={item.id}>
                                  {item.name}
                                </Select.Option>
                              );
                            } else {
                              groups.push({
                                label: groupLabel,
                                options: [
                                  <Select.Option value={item.id}>
                                    {item.name}
                                  </Select.Option>,
                                ],
                              });
                            }
                            return groups;
                          }, [])
                          .map((group, index) => (
                            <Select.OptGroup label={group.label}>
                              {group.options}
                            </Select.OptGroup>
                          ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      ghost={!isUpdate}
                      style={{ width: "2.5vw" }} //referencia
                      icon={<UserAddOutlined />}
                      onClick={() => handleNew()}
                    ></Button>
                  </Form.Item>
                </Descriptions.Item>
              )}
              {formType === "warehousesTransfer" && (
                <Descriptions.Item label="Cliente/Proveedor/Centro">
                  <label className="asterisco">*</label>
                  <Form.Item
                    name="destinationDistributionCenterId"
                    rules={[
                      { required: true, message: "Error: es obligatorio" },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione..."
                      allowClear
                      className="form-element"
                      disabled={isUpdate ? true : false}
                      onChange={(value, object) => {
                        updateCentroDistribution(
                          dataWarehouses.data.distributionCenters.filter(
                            (item) => item.id === value
                          )[0]
                        );
                        setFormCustomer(
                          dataCustomers.data.clients.filter(
                            (item) => item.id === value
                          )[0]
                        );
                        form.setFieldsValue({
                          destinationDistributionCenterId: value,
                          destinationDistributionCenterName: object
                            ? object.children
                            : "",
                        });

                        setSelectedAddress(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      style={{
                        width: 200,
                      }}
                    >
                      {dataCustomers.called &&
                        dataCustomers.data !== undefined &&
                        dataCustomers.data.clients
                          .reduce((groups, item) => {
                            const groupLabel =
                              item.person === "customer"
                                ? "Clientes"
                                : item.person === "supplier"
                                ? "Proveedores"
                                : "";
                            const groupIndex = groups.findIndex(
                              (group) => group.label === groupLabel
                            );
                            if (groupIndex !== -1) {
                              groups[groupIndex].options.push(
                                <Select.Option
                                  value={item.id}
                                >{`${item.name} (${item.documentType} ${item.nit})`}</Select.Option>
                              );
                            } else {
                              groups.push({
                                label: groupLabel,
                                options: [
                                  <Select.Option
                                    value={item.id}
                                  >{`${item.name} (${item.documentType} ${item.nit})`}</Select.Option>,
                                ],
                              });
                            }
                            return groups;
                          }, [])
                          .map((group, index) => (
                            <Select.OptGroup label={group.label}>
                              {group.options}
                            </Select.OptGroup>
                          ))}
                      {dataWarehouses.called &&
                        dataWarehouses.data !== undefined &&
                        dataWarehouses.data.distributionCenters
                          .reduce((groups, item) => {
                            const groupLabel = "Centro";
                            const groupIndex = groups.findIndex(
                              (group) => group.label === groupLabel
                            );
                            if (groupIndex !== -1) {
                              groups[groupIndex].options.push(
                                <Select.Option value={item.id}>
                                  {item.name}
                                </Select.Option>
                              );
                            } else {
                              groups.push({
                                label: groupLabel,
                                options: [
                                  <Select.Option value={item.id}>
                                    {item.name}
                                  </Select.Option>,
                                ],
                              });
                            }
                            return groups;
                          }, [])
                          .map((group, index) => (
                            <Select.OptGroup label={group.label}>
                              {group.options}
                            </Select.OptGroup>
                          ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      ghost={!isUpdate}
                      style={{ width: "2.5vw" }} //referencia
                      icon={<UserAddOutlined />}
                      onClick={() => handleNew()}
                    ></Button>
                  </Form.Item>
                </Descriptions.Item>
              )}

              {!isUpdate ? (
                <Descriptions.Item label="Ruta en Ejecucion">
                  <Form.Item
                    rules={[{ required: true, message: "Es requerido" }]}
                  >
                    <Select
                      className="form-element"
                      placeholder="Seleccione..."
                      allowClear
                      onChange={(value, object) => {
                        form.setFieldsValue({
                          routeRoutingId: value,
                          routeRoutingName: object?.children,
                        });

                        setDataRoute(
                          dataRouteRunning.data.userVehicle.filter(
                            (item) => item.id === value
                          )[0]
                        );
                      }}
                      showSearch
                      style={{
                        width: 200,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            optionB.children.toString().toLowerCase()
                          )
                      }
                    >
                      {dataRouteRunning.called &&
                        dataRouteRunning.data !== undefined &&
                        dataRouteRunning.data.userVehicle.map((item) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.vehicle.licencePlate}{" "}
                              {item.user.displayName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </Card>

          <Card
            title={
              formType === "pickup"
                ? "Información del origen"
                : "Información del destino"
            }
            size="small"
          >
            <Descriptions
              style={{ marginBottom: "10px" }}
              layout="vertical"
              size="small"
              column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
              {(formType === "delivery" || formType === "pickup") && (
                <Descriptions.Item label="Dirección">
                  <Form.Item name="addressBookId">
                    <Select
                      placeholder="Seleccione..."
                      allowClear
                      className="form-element-with-button"
                      onChange={(value, object) => {
                        setFormAddressBook(
                          dataAddressBook.data.clientAddresses.filter(
                            (item) => item.id === value
                          )[0]
                        );
                        form.setFieldsValue({
                          addressBookId: value,
                          addressBookName: object ? object.children : "",
                        });
                        if (object)
                          updateAddressBook(
                            dataAddressBook.data.clientAddresses.filter(
                              (item) => item.id === value
                            )[0]
                          );
                      }}
                      showSearch
                      style={{
                        width: 200,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {dataAddressBook.called &&
                        dataAddressBook.data !== undefined &&
                        dataAddressBook.data.clientAddresses.map((item) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ width: "2.5vw" }}
                      type="primary"
                      disabled={selectedAddress === false}
                      ghost={!isUpdate}
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        handleNewAddress();
                        setCustomerSupplier(
                          form.getFieldValue("custIdentificationNumber")
                        );
                      }}
                    ></Button>
                  </Form.Item>
                </Descriptions.Item>
              )}

              <Descriptions.Item label="Departamento">
                <Form.Item
                  className="form-element"
                  name="state"
                  onChange={(value, object) => {
                    destinarionAutoComplet(
                      value.target.value,
                      form.getFieldValue("city"),
                      form.getFieldValue("address")
                    );
                  }}
                  rules={[
                    { required: isRequired("state"), message: "" },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("state", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Ciudad">
                <Form.Item
                  className="form-element"
                  name="city"
                  onChange={(value, object) => {
                    destinarionAutoComplet(
                      form.getFieldValue("state"),
                      value.target.value,
                      form.getFieldValue("address")
                    );
                  }}
                  rules={[
                    { required: isRequired("city"), message: "" },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("city", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Dirección de entrega">
                <label className="asterisco">*</label>
                <Form.Item
                  name="address"
                  className="form-element"
                  onChange={(value, object) => {
                    destinarionAutoComplet(
                      form.getFieldValue("state"),
                      form.getFieldValue("city"),
                      value.target.value
                    );
                  }}
                  rules={[
                    { required: isRequired("address"), message: "" },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("address", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Complemento de dirección">
                <Form.Item name="addressComplement" className="form-element">
                  <Input />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Latitud y longitud">
                <label className="asterisco">*</label>
                <Form.Item
                  name="destination"
                  rules={[{ required: true, message: "Error: es obligatorio" }]}
                >
                  <Input className="form-element" disabled />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Zona">
                <label className="asterisco">*</label>
                <Form.Item
                  name="sectorId"
                  rules={[{ required: true, message: "Error: es obligatorio" }]}
                >
                  <Select
                    className="form-element"
                    placeholder="Seleccione..."
                    allowClear
                    onChange={(value, object) => {
                      if (value != undefined) {
                        setSelectedSector(
                          sectors.find((item) => item.id === value).name
                        );
                        form.setFieldsValue({
                          sectorId: value,
                          sectorName: object.children,
                        });
                      }
                    }}
                    showSearch
                    style={{
                      width: 200,
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input.toUpperCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toUpperCase()
                        .localeCompare(optionB.children.toUpperCase())
                    }
                  >
                    {dataSectors.called &&
                      dataSectors.data !== undefined &&
                      dataSectors.data.sectors.map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Prioridad">
                <label className="asterisco">*</label>
                <Form.Item
                  name="priority"
                  rules={[{ required: true, message: "Error: es obligatorio" }]}
                >
                  <Select
                    className="form-element"
                    placeholder="Seleccione..."
                    allowClear
                    onChange={(value, object) => {
                      if (value != undefined) {
                        form.setFieldsValue({
                          priority: value,
                          priorityName: object.children,
                        });
                      }
                    }}
                    showSearch
                    style={{
                      width: 200,
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    <Select.Option key={"a_high"} value={"a_high"}>
                      Alto
                    </Select.Option>
                    <Select.Option key={"b_medium"} value={"b_medium"}>
                      Medio
                    </Select.Option>
                    <Select.Option key={"c_low"} value={"c_low"}>
                      Bajo
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Notas">
                <label className="asterisco">*</label>
                <Form.Item
                  className="form-element"
                  name="notes"
                  rules={[
                    { required: isRequired("notes"), message: "" },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("notes", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>
              {geoClouser?.name !== selectedSector &&
              geoClouser?.id !== form.getFieldValue("sectorId") &&
              geoClouser !== null &&
              form.getFieldValue("destination") !== "" ? (
                <Descriptions.Item>
                  <Form.Item>
                    <p className="Info-Geo-Clouser">
                      La ubicación se encuentra dentro de la zona{" "}
                      {geoClouser?.name} pero esta no es la seleccionada
                    </p>
                  </Form.Item>
                </Descriptions.Item>
              ) : (
                ""
              )}
            </Descriptions>
          </Card>
          <Card
            title={
              formType === "pickup"
                ? "Información del proveedor"
                : "Información del cliente"
            }
            size="small"
          >
            <Row justify="space-between">
              <Col>
                <Form.Item name="newClient" valuePropName="checked">
                  <Checkbox onChange={handleNewClient}>Cliente nuevo</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Descriptions
              style={{ marginBottom: "11px" }}
              layout="vertical"
              size="small"
              column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Identificación cliente">
                <label className="asterisco">*</label>
                <Form.Item
                  className="form-element"
                  name="custIdentificationNumber"
                  rules={[
                    {
                      required: isRequired("custIdentificationNumber"),
                      message: "campo requerido",
                    },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res =
                          value == undefined
                            ? ""
                            : validateField("custIdentificationNumber", value);
                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  {isSelectActive === true ? (
                    <Select
                      className="form-element"
                      placeholder="Seleccione..."
                      allowClear
                      showSearch
                      style={{ width: 200 }}
                      disabled={isUpdate ? true : false}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toUpperCase()
                          .localeCompare(optionB.children.toUpperCase())
                      }
                      onChange={handleChange}
                    >
                      {dataClient.called &&
                        dataClient.data !== undefined &&
                        dataClient.data.clients.map((item) => {
                          return (
                            <Select.Option key={item.nit} value={item.nit}>
                              {`${item.nit}-${item.name}`}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Input disabled={isUpdate ? true : false} />
                  )}
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Nombre del cliente">
                <label className="asterisco">*</label>
                <Form.Item
                  className="form-element"
                  name="custFullName"
                  rules={[
                    { required: isRequired("custFullName"), message: "" },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("custFullName", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input disabled={isUpdate ? true : false} />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Celular cliente">
                <label className="asterisco">*</label>
                <Form.Item
                  className="form-element"
                  name="custPhoneNumber"
                  rules={[
                    { required: isRequired("custPhoneNumber"), message: "" },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("custPhoneNumber", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Email cliente">
                <Form.Item
                  className="form-element"
                  name="custEmail"
                  rules={[
                    {
                      required: isRequired("custEmail"),
                      message: "",
                      type: "email",
                    },
                  ]}
                >
                  <Input disabled={isUpdate ? true : false} />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Tipo de Persona">
                <label className="asterisco">*</label>
                <Form.Item
                  className="form-element"
                  name="typePerson"
                  rules={[
                    {
                      required: isRequired("typePerson"),
                      message: "campo requerido",
                    },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField("typePerson", value);

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Select
                    className="form-element"
                    placeholder="Seleccione..."
                    allowClear
                    showSearch
                  >
                    return(
                    <Select.Option value={"Persona jurídica"}>
                      Persona jurídica
                    </Select.Option>
                    <Select.Option value={"Persona natural"}>
                      Persona natural
                    </Select.Option>
                    )
                  </Select>
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  formType === "pickup" ? "Requiere Pago" : "Requiere Cobro"
                }
              >
                <Form.Item
                  className="form-element"
                  name="paymentOrCashOnDeliveryRequired"
                  rules={[
                    {
                      required: isRequired("paymentOrCashOnDeliveryRequired"),
                      message: "",
                    },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField(
                          "paymentOrCashOnDeliveryRequired",
                          value
                        );

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Checkbox
                    allowClear
                    checked={
                      formPaymentOrCashOnDeliveryRequiredd === true
                        ? false
                        : formPaymentOrCashOnDeliveryRequired
                    }
                    onChange={(value, object) => {
                      setFormPaymentOrCashOnDeliveryRequiredd(false);
                      setFormPaymentOrCashOnDeliveryRequired(
                        value.target.checked
                      );

                      if (value.target.checked === true) {
                        updateUploadDataController({
                          variables: {
                            id: "f3e7db84-649c-459b-9132-e770880ac1e8",
                            mandatory: true,
                            minValue: 1,
                            maxValue: null,
                          },
                          fetchPolicy: "no-cache",
                        }).catch((error) => {
                          console.error(error);
                        });
                      } else {
                        updateUploadDataController({
                          variables: {
                            id: "f3e7db84-649c-459b-9132-e770880ac1e8",
                            mandatory: false,
                            minValue: null,
                            maxValue: null,
                          },
                          fetchPolicy: "no-cache",
                        }).catch((error) => {
                          console.error(error);
                        });
                      }
                      form.setFieldsValue({
                        paymentOrCashOnDeliveryRequired:
                          formPaymentOrCashOnDeliveryRequiredd === false
                            ? value.target.checked
                            : false,
                      });
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>

              {formPaymentOrCashOnDeliveryRequired && (
                <Descriptions.Item label="Método de pago">
                  <Form.Item
                    className="form-element"
                    name="paymentMethod"
                    rules={[
                      { required: isRequired("paymentMethod"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("paymentMethod", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Select
                      className="form-element"
                      placeholder="Seleccione..."
                      allowClear
                      disabled={isUpdate ? true : false}
                      onChange={(value, object) => {
                        form.setFieldsValue({
                          paymentMethod: value,
                        });
                      }}
                      showSearch
                      style={{
                        width: 200,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {dataPaymentMethods.called &&
                        dataPaymentMethods.data !== undefined &&
                        dataPaymentMethods.data.paymentMethods.map((item) => {
                          return (
                            <Select.Option key={item.name} value={item.name}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formPaymentOrCashOnDeliveryRequired && (
                <Descriptions.Item label="Monto total">
                  <label className="asterisco">*</label>
                  <Form.Item
                    name="totalOrderAmount"
                    rules={[
                      {
                        required: isRequired("totalOrderAmount"),
                        message: "Campo Obligatorio",
                      },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("totalOrderAmount", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject("Error: es obligatorio");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
          <Card
            title={
              formType === "pickup"
                ? "Compromiso de recogida"
                : "Compromiso de entrega"
            }
            size="small"
          >
            <Descriptions
              style={{ marginBottom: "10px" }}
              layout="vertical"
              size="small"
              column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
              <Descriptions.Item
                label={
                  formType === "pickup"
                    ? "Habilitar ventana de recogida"
                    : "Habilitar ventana de entrega"
                }
              >
                <Form.Item
                  name="enableDeliveryWindow"
                  rules={[
                    {
                      required: isRequired("enableDeliveryWindow"),
                      message: "",
                    },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField(
                          "enableDeliveryWindow",
                          value
                        );

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Checkbox
                    allowClear
                    checked={
                      formPaymentOrCashOnDeliveryRequiredd === true
                        ? false
                        : formEnableDeliveryWindow
                    }
                    className="form-element"
                    onChange={(value, object) => {
                      setFormPaymentOrCashOnDeliveryRequiredd(false);
                      setFormEnableDeliveryWindow(value.target.checked);
                      form.setFieldsValue({
                        enableDeliveryWindow: value.target.checked,
                      });
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>

              {formEnableDeliveryWindow && (
                <Descriptions.Item label="Fecha inicial">
                  <Form.Item
                    name="expectedDeliveryStartTime"
                    rules={[
                      {
                        required: isRequired("expectedDeliveryStartTime"),
                        message: "",
                      },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField(
                            "expectedDeliveryStartTime",
                            value
                          );

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      className="form-element"
                      showTime
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnableDeliveryWindow && (
                <Descriptions.Item label="Fecha final">
                  <Form.Item
                    name="expectedDeliveryEndTime"
                    rules={[
                      {
                        required: isRequired("expectedDeliveryEndTime"),
                        message: "",
                      },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField(
                            "expectedDeliveryEndTime",
                            value
                          );

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      className="form-element"
                      showTime
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>

          <Card title="Información adicional" size="small">
            <Descriptions
              style={{ marginBottom: "10px" }}
              layout="vertical"
              size="small"
              column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
              {(formType === "delivery" || formType === "pickup") && (
                <Descriptions.Item label="Factura">
                  <Form.Item className="form-element" name="consecutiveBill">
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {(formType === "delivery" || formType === "pickup") && (
                <Descriptions.Item label="Remisión">
                  <Form.Item
                    className="form-element"
                    name="consecutiveRemission"
                  >
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formType === "warehousesTransfer" && (
                <Descriptions.Item label="Número de Traslado">
                  <Form.Item className="form-element" name="consecutiveTRM">
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formType === "delivery" && (
                <Descriptions.Item label="Pedido de Venta">
                  <Form.Item
                    className="form-element"
                    name="consecutiveSaleOrder"
                  >
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formType === "warehousesTransfer" && (
                <Descriptions.Item label="Número del Envío">
                  <Form.Item
                    className="form-element"
                    name="consecutiveShipping"
                  >
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formType === "warehousesTransfer" && (
                <Descriptions.Item label="Número de la Carga">
                  <Form.Item className="form-element" name="consecutiveBurden">
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>

          <Card title="Información de la mercancia" size="small">
            <Descriptions
              style={{ marginBottom: "10px" }}
              layout="vertical"
              size="small"
              column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Habilitar dimensiones del paquete">
                <Form.Item
                  name="enablePackageDimensions"
                  rules={[
                    {
                      required: isRequired("enablePackageDimensions"),
                      message: "",
                    },
                    ({ _, getFieldInstance }) => ({
                      validator(obj, value) {
                        const res = validateField(
                          "enablePackageDimensions",
                          value
                        );

                        if (res.startsWith("Error")) {
                          return Promise.reject(res);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Checkbox
                    allowClear
                    className="form-element"
                    checked={
                      formPaymentOrCashOnDeliveryRequiredd === true
                        ? false
                        : formEnablePackageDimensions
                    }
                    onChange={(value, object) => {
                      setFormPaymentOrCashOnDeliveryRequiredd(false);
                      setFormEnablePackageDimensions(value.target.checked);
                      form.setFieldsValue({
                        enablePackageDimensions: value.target.checked,
                      });
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Tipo de Paquete">
                  <Form.Item
                    name="packageId"
                    rules={[
                      { required: true, message: "Error: es obligatorio" },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione..."
                      allowClear
                      className="form-element"
                      disabled={isUpdate ? true : false}
                      onChange={(value, object) => {
                        setFormPackage(
                          dataPackages.data.packages.filter(
                            (item) => item.id === value
                          )[0]
                        );
                        form.setFieldsValue({
                          packageId: value,
                          packageName: object ? object.children : "",
                        });
                        if (object)
                          updatePackages(
                            dataPackages.data.packages.filter(
                              (item) => item.id === value
                            )[0]
                          );
                      }}
                      showSearch
                      style={{
                        width: 200,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {dataPackages.called &&
                        dataPackages.data !== undefined &&
                        dataPackages.data.packages.map((item) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Peso">
                  <Form.Item
                    name="weight"
                    rules={[
                      { required: isRequired("weight"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("weight", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Alto">
                  <Form.Item
                    name="height"
                    rules={[
                      { required: isRequired("height"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("height", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Ancho">
                  <Form.Item
                    name="width"
                    rules={[
                      { required: isRequired("width"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("width", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Largo">
                  <Form.Item
                    name="depth"
                    rules={[
                      { required: isRequired("depth"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("depth", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Metros cúbicos">
                  <Form.Item
                    name="cubicMeters"
                    rules={[
                      { required: isRequired("cubicMeters"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("cubicMeters", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Cantidad">
                  <Form.Item
                    name="orderedQuantity"
                    rules={[
                      { required: isRequired("orderedQuantity"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("orderedQuantity", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      onChange={(value, object) => updateTotalCubicMeter(value)}
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Metros Cúbicos Totales">
                  <Form.Item
                    name="totalCubicMeters"
                    rules={[
                      { required: isRequired("totalCubicMeters"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("totalCubicMeters", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-element"
                      min={0}
                      disabled={isUpdate ? true : false}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Contenido del paquete">
                  <Form.Item
                    className="form-element"
                    name="packageContent"
                    rules={[
                      { required: isRequired("packageContent"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("packageContent", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input disabled={isUpdate ? true : false} />
                  </Form.Item>
                </Descriptions.Item>
              )}

              {formEnablePackageDimensions && (
                <Descriptions.Item label="Habilitar detalle de lineas">
                  <Form.Item
                    name="linesDetail"
                    rules={[
                      { required: isRequired("linesDetail"), message: "" },
                      ({ _, getFieldInstance }) => ({
                        validator(obj, value) {
                          const res = validateField("linesDetail", value);

                          if (res.startsWith("Error")) {
                            return Promise.reject(res);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Checkbox
                      allowClear
                      className="form-element"
                      checked={
                        formPaymentOrCashOnDeliveryRequiredd === true
                          ? false
                          : formLinesDetail
                      }
                      onChange={(value, object) => {
                        setFormPaymentOrCashOnDeliveryRequiredd(false);
                        setFormLinesDetail(value.target.checked);
                        form.setFieldsValue({
                          linesDetail: value.target.checked,
                        });
                      }}
                    />
                  </Form.Item>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </Space>
        <Form.Item>
          {!isUpdate && formLinesDetail === true ? (
            <Space className="form-element2">
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                onClick={hasEmptyFields}
              >
                Aceptar
              </Button>

              <Button type="ghost" shape="round" onClick={handleClear}>
                Limpiar
              </Button>
            </Space>
          ) : isUpdate && formLinesDetail === true ? (
            <Space className="form-element2">
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={isUpdate && ordersLines.length > 0}
                onClick={() => {
                  hasEmptyFields();
                }}
              >
                Aceptar
              </Button>

              <Button type="ghost" shape="round" onClick={handleClear}>
                Limpiar
              </Button>
            </Space>
          ) : !isUpdate && formLinesDetail === false ? (
            <Space className="form-element2">
              <Button
                style={{ marginTop: 10 }}
                type="primary"
                shape="round"
                htmlType="submit"
                onClick={hasEmptyFields}
              >
                Aceptar
              </Button>
              <Button type="ghost" shape="round" onClick={handleClear}>
                Limpiar
              </Button>
            </Space>
          ) : isUpdate && formLinesDetail === false ? (
            <Space className="form-element2">
              <Button
                style={{ marginTop: 10 }}
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={isUpdate && ordersLines.length > 0}
                onClick={hasEmptyFields}
              >
                Aceptar
              </Button>
              <Button type="ghost" shape="round" onClick={handleClear}>
                Limpiar
              </Button>
            </Space>
          ) : (
            ""
          )}
        </Form.Item>
        <Form.Item name="deleteable" hidden valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="vehicleBrandName" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="vehicleTypeName" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="transportCompanyName" hidden>
          <Input />
        </Form.Item>
      </Form>

      <div>
        {formLinesDetail && (
          <div key="lines">
            <LinesUploadScreen
              hasOrder={hasOrder}
              ordersLine={isUpdate ? ordersLines : ordersLine}
              setOrdersLine={isUpdate ? setOrdersLines : setOrdersLine}
              form={form}
              isUpdate={isUpdate}
            />
          </div>
        )}

        <div>
          {!isUpdate &&
          formLinesDetail === false &&
          formSinLinesDetail === true ? (
            <Button
              disabled={ordersLine.length === 0 && formLinesDetail}
              style={{ marginTop: 10 }}
              type="primary"
              shape="round"
              icon={<SaveFilled />}
              onClick={() => {
                handleSaveNoLines();
              }}
            >
              Insertar Orden
            </Button>
          ) : !isUpdate && formLinesDetail ? (
            <Button
              disabled={ordersLine.length === 0 && formLinesDetail}
              style={{ marginTop: 10 }}
              type="primary"
              shape="round"
              icon={<SaveFilled />}
              onClick={() => {
                handleSave();
              }}
            >
              Insertar Orden
            </Button>
          ) : isUpdate && formLinesDetail ? (
            <Button
              disabled={ordersLine.length === 0 || ordersLines.length === 0}
              style={{ marginTop: 10 }}
              type="primary"
              shape="round"
              icon={<SaveFilled />}
              onClick={() => {
                handleSave();
              }}
            >
              Editar Orden
            </Button>
          ) : isUpdate &&
            formLinesDetail === false &&
            formSinLinesDetail === true ? (
            <Button
              disabled={ordersLine.length === 0 && formLinesDetail}
              style={{ marginTop: 10 }}
              type="primary"
              shape="round"
              icon={<SaveFilled />}
              onClick={() => {
                handleSaveNoLines();
              }}
            >
              Editar Orden
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      {showDrawer === true ? (
        <UserNew
          props={{
            showDrawer,
            setShowDrawer,
            getCustomers,
            setFormValue: form,
            setFormCustomer,
            setSelectedAddress,
            setSelectedInfo,
            formType,
          }}
        />
      ) : (
        <Row></Row>
      )}
      {showDrawerAddress === true ? (
        <AddressNew
          props={{
            showDrawerAddress,
            setShowDrawerAddress,
            formCustomer,
            setClientAddresses,
            clientAddresses,
            getAddressBook,
            distributionCenters,
            sectors,
          }}
        />
      ) : (
        <Row></Row>
      )}
    </div>
  );
};
