import React, { useEffect, useRef, useState } from 'react';
import {MapSectorsSreen} from  '../../../sectors/ui/screens/MapSectorsSreen';
export default function ZonesConfiguration() {

    return (
        <div>
           <MapSectorsSreen></MapSectorsSreen>
        </div>
    );
}
