import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { useTMSStore } from "../../../../../../../store/tms-store";
import "../../stylesTMS/styles-tms.css"

export const TMSSearchTextUm = ({ isBlankActiveUm, onSearch }) => {
  const loading = useTMSStore((state) => state.loading);
  const setSearchTextUm = useTMSStore((state) => state.setSearchTextUm);
  const [searchFilterUm, setSearchFilterUm] = useState("");
  const [inputValue, setInputValue] = useState(isBlankActiveUm);

  useEffect(() => {
    setInputValue(isBlankActiveUm);
  }, [isBlankActiveUm]);

  const textFieldEvent = (ev) => {
    const inputValue = ev.target.value;
    setSearchFilterUm(inputValue);
  };

  useEffect(() => {

    if (inputValue) {

      setSearchFilterUm("");
    }
  }, [inputValue, setSearchTextUm]);


  const textFieldOnKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      setSearchTextUm(searchFilterUm)
      setInputValue(false);
    }
  };

  return (
    <TextField
      onKeyDown={textFieldOnKeyDown}
      onChange={textFieldEvent}
      placeholder="Buscar UM"
      className="tms-search-textfield"
      value={searchFilterUm}
      color="error"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              {loading ? (
                <CircularProgress size={20} color="error" />
              ) : (
                <Search
                  onClick={() => {
                    setSearchTextUm(searchFilterUm); // Guarda el valor en Zustand
                    onSearch(); // Ejecuta la búsqueda
                  }}
                  className="button-search"
                />

              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};