import { gql } from "@apollo/client";

export const getUsers = gql`
query GetUsers {
    users(order_by: {createdAt: asc}) {
      emailVerified
          enabled
          address
          deviceToken
          displayName
          driverLicense
          email
          identificationNumber
          password
          phoneNumber
          photoURL
          createdAt
          updatedAt
          role {
            label
          }
          companyId
          distributionCenterId
          id
          userDeliveryCenters{
            distributionCenterId,
            distributionCenter{
              name
            }
          }
          roleId
          userDistributionCenter {
            name
          }
    }
  }
   `;

   export const getUsersByName = gql`
   query getUsersByName($displayName: String!) {
     users(order_by: { createdAt: asc }, where: { displayName: { _ilike: $displayName } }) {
       emailVerified
       enabled
       address
       deviceToken
       displayName
       driverLicense
       email
       identificationNumber
       password
       phoneNumber
       photoURL
       createdAt
       updatedAt
       role {
         label
       }
       companyId
       distributionCenterId
       id
       userDeliveryCenters {
         distributionCenterId
         distributionCenter {
           name
         }
       }
       roleId
       userDistributionCenter {
         name
       }
     }
   }
 `;
 

export const getRoles = gql`
query GetRoles {
    roles {
      label
          name
          createdAt
          updatedAt
          id
    }
  }`;

export const deleteUser = gql`
  mutation MyMutation($id: uuid!) {
    delete_users(where: {id: {_eq: $id}}) {
      returning {
        displayName
        id
      }
    }
  }`;

export const insertUserQuery = gql`
  mutation MyMutation(
    $address: String!,
    $email: String!,
    $identificationNumber: String!,
    $phoneNumber: String!,
    $roleId: uuid!,
    $emailVerified: Boolean!,
    $displayName: String!,
    $distributionCenterId: uuid!,
    $enabled: Boolean!
  ) {
    insert_users(
      objects: {
        address: $address,
        email: $email,
        identificationNumber: $identificationNumber,
        phoneNumber: $phoneNumber,
        roleId: $roleId,
        emailVerified: $emailVerified,
        displayName: $displayName,
        distributionCenterId: $distributionCenterId
        companyId: "482777f8-95e3-4b2a-8a78-7c75aa733946",
        enabled: $enabled
      }
    ) {
      returning {
        address
        distributionCenterId
        displayName
        email
        emailVerified
        id
        identificationNumber
        roleId
      }
    }
  }
`;

export const updateUserQuery = gql`
mutation UpdateUser($id: uuid!, $address: String, $displayName: String, $emailVerified: Boolean, $identificationNumber: String, $phoneNumber: String, $roleId: uuid, $email: String) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {address: $address, displayName: $displayName, emailVerified: $emailVerified, identificationNumber: $identificationNumber, phoneNumber: $phoneNumber, roleId: $roleId, email: $email}) {
      id
      address
      displayName
      emailVerified
      identificationNumber
      phoneNumber
      roleId
      email
    }
  }`;

  export const updateUser = gql`
  mutation updateUser(
    $id: uuid!
    $address: String!
    $displayName: String!
    $driverLicense: String
    $email: String!
    $enabled: Boolean!
    $identificationNumber: String!
    $phoneNumber: String!
    $roleId: uuid!
    $photoURL: String
    $isEmailVerified: Boolean!
  ) {
    update_users(
      _set: {
        address: $address
        displayName: $displayName
        driverLicense: $driverLicense
        email: $email
        enabled: $enabled
        identificationNumber: $identificationNumber
        phoneNumber: $phoneNumber
        photoURL: $photoURL
        roleId: $roleId
        emailVerified: $isEmailVerified
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const cleanDistrutionCenter = gql`
  mutation deleteUserDistributionCenters($id: uuid!) {
    delete_userDistributionCenters(where: {userId: {_eq: $id}}) {
        affected_rows
    }
  }  
`;

export const deleteUserWithoutRoute = gql`
  mutation deleteUser($id: uuid) {
    delete_users(where: {id: {_eq: $id}}) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const fetchDistributionCenters = gql`
  query fetchDistributionCenters($companyId: uuid!) {
    distributionCenters(
      where: { companyId: { _eq: $companyId }, enabled: { _eq: true } }
    ) {
      id
      name
    }
  }
`;

export const updateDistributionCenters = gql`
  mutation insertUserDistributionCenters($distributionCenters: [userDistributionCenters_insert_input!]!) {
    insert_userDistributionCenters(objects: $distributionCenters) {
      affected_rows
      returning{
        id
      }
    }
  }
`;

export const updateUserInsertAutomaticPassword = gql`
mutation updateUserInsertAutomaticPassword($id: uuid!, $password: String!) {
  update_users(_set: {password: $password, emailVerified: true}, where: {id: {_eq: $id}}) {
      affected_rows
  }
}
`;

export const activeUser = gql`
  mutation updateUser(
    $id: uuid!
  ) {
    update_users(
      _set: {
        enabled: true
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const checkIfIdentificationNumberEmailExists = gql`
  query checkIfIdentificationNumberEmailExists(
    $identification: String!
    $email: String!
  ) {
    users(
      where: {
        _or: [
          { identificationNumber: { _eq: $identification } },
          { email: { _eq: $email } }
        ]
      }
    ) {
      id
    }
  }
`;

export const checkIfIdentificationNumberEmailExistsForUpdate = gql`
  query checkIfIdentificationNumberEmailExistsForUpdate(
    $id: uuid!
    $identification: String!
    $email: String!
  ) {
    users(
      where: {
        _and: [
          { id: { _neq: $id } },  
          {
            _or: [
              { identificationNumber: { _eq: $identification } },
              { email: { _eq: $email } }
            ]
          }
        ]
      }
    ) {
      id
    }
  }
`;


