import { create } from 'zustand'
export const useTMSStore = create((set) => ({
  loads: [],
  count: 0,
  warehousesname: "",
  pageSize: 30,
  pageId: 1,
  fromDate: "",
  toDate: "",
  searchText: "",
  loading: false,
  initLoading: false,
  seeDispatched: 0,
  seeDelivery: 0,
  transport: "NAVI",
  ovTrn: "",
  um: "",
  setLoadsData: ({loads, count}) => set({ loads, count, loading: false, initLoading: false }),
  setWarehousesName: (warehousesname) => set({ warehousesname }),
  startLoading: () => set({ loading: true }),
  startInitLoading: () => set({ initLoading: true }),
  setSearchText: (searchText) => set({ searchText }),
  setSearchTextTransport: (transport) => set({ transport }),
  setSearchTextOvTrn: (ovTrn) => set({ ovTrn }),
  setPageSize: (pageSize) => set({ pageSize }),
  setPageId: (pageId) => set({ pageId }),
  setSeeDispatched: (seeDispatched) => set({ seeDispatched }),
  setSeeDelivery: (seeDelivery) => set({ seeDelivery }),
  setSearchFromDate: (fromDate) => set({ fromDate }),//
  setSearchToDate: (toDate) => set({ toDate }), // funcion 
  setSearchTextUm: (um) => set({ um }),
}));