import React, { useEffect, useRef, useState } from 'react';
import { ReasonsScreen } from '../screens/reasons/ui/screens/ReasonsScreen'

export default function ReasonConfiguration() {

    return (
        <div>
          <ReasonsScreen></ReasonsScreen>
        </div>
    );
}