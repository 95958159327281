import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import { basicTheme } from "../../../../map-themes/basicTheme";
import { v4 as uuidv4 } from 'uuid';
import decodePolyline from 'decode-google-map-polyline';
import pin from "../../../../images/PuntoNaranja.png";
import finishPin from "../../../../images/PuntoNaranjaVacio.png";

import pinDeliveryRoute from "../../../../images/PuntoAzul.png";
import finishPinDeliveryRoute from "../../../../images/PuntoAzulVacio.png";



const googleAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const libraries = ["places"];

const options = {
  styles: basicTheme,
  disableDefaultUI: true,
  zoomControl: false,
};

//Google Maps API Key
export const DeliveryRoute = (props) => {
  const { visibleTelemetry, dataTelemetry, polylines } = props;
//Ruta planificada
  const [deliveryRoute, setDeliveryRoute] = useState([]);
  const mapRefDeliveryRoute = useRef();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleAPI,
    libraries,
  });

  useEffect(() => {
    const polylinesCoords = createRouteCoords(polylines);
    setDeliveryRoute(polylinesCoords[0].coords);
  }, [polylines]);

  const createRouteCoords = (points) => {
    let steps = decodePolyline(points);
    let coords = [];

    coords = [{ polylineId: uuidv4(), coords: steps }];

    return coords;
};

  // Obtener la posición inicial de la entrega
  const startPosition = {
    lat:
      dataTelemetry.data?.telemetry[0] !== undefined
        ? parseFloat(dataTelemetry.data?.telemetry[0]?.latitude)
        : [],
    lng:
      dataTelemetry.data?.telemetry[0] !== undefined
        ? parseFloat(dataTelemetry.data?.telemetry[0]?.longitude)
        : [],
  };

  // Obtener la posición final de la entrega
  const endPosition = {
    lat:
      dataTelemetry.data?.telemetry[0] !== undefined
        ? parseFloat(
            dataTelemetry.data?.telemetry[
              dataTelemetry.data?.telemetry.length - 1
            ].latitude
          )
        : [],
    lng:
      dataTelemetry.data?.telemetry[0] !== undefined
        ? parseFloat(
            dataTelemetry.data?.telemetry[
              dataTelemetry.data?.telemetry.length - 1
            ].longitude
          )
        : [],
  };

  // Crear la ruta a partir de los datos de telemetría
  const path = dataTelemetry.data?.telemetry.map((data) => ({
    lat: parseFloat(data.latitude),
    lng: parseFloat(data.longitude),
  }));

  const onMapLoad = useCallback((map) => {
    mapRefDeliveryRoute.current = map;
  }, []);


  if (loadError) {
    return <div>Error al cargar el mapa</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapa...</div>;
  }

  if (!visibleTelemetry || dataTelemetry.data?.telemetry[0] === undefined) {
    return <div
    style={{
      height: "260px",
      maxHeight: "260px",
    }}>No hay datos de la ruta</div>;
  }

  return (
    <section
      style={{
        height: "260px",
        maxHeight: "260px",
      }}
    >
      <GoogleMap
        mapContainerStyle={{
          height: "260px",
          maxHeight: "260px",
          width: "100%",
          borderRadius: "10px",
        }}
        zoom={14}
        center={endPosition}
        options={options}
        onLoad={onMapLoad}
        id="mapa-google-map"
      >
        {/* Dibujar la ruta */}
        <Polyline path={path} options={{ strokeColor: "#4680FF" }} />

        {/* Dibujar la ruta plafinicada */}
        <Polyline path={deliveryRoute} options={{ strokeColor: "#FFBA57" }} />

        {/* Marcador de inicio */}
        <Marker
          position={deliveryRoute[0]}
          icon={
            isLoaded
              ? {
                  url: pin,
                  scaledSize: new window.google.maps.Size(30, 33),
                }
              : null
          }
        />

        {/* Marcador de fin */}
        <Marker
          position={deliveryRoute[deliveryRoute.length - 1]}
          icon={
            isLoaded
              ? {
                  url: finishPin,
                  scaledSize: new window.google.maps.Size(30, 33),
                }
              : null
          }
        />

        {/* Marcador de inicio */}
        <Marker
          position={startPosition}
          icon={
            isLoaded
              ? {
                  url: pinDeliveryRoute,
                  scaledSize: new window.google.maps.Size(30, 33),
                }
              : null
          }
        />

        {/* Marcador de fin */}
        <Marker
          position={endPosition}
          icon={
            isLoaded
              ? {
                  url: finishPinDeliveryRoute,
                  scaledSize: new window.google.maps.Size(30, 33),
                }
              : null
          }
        />
      </GoogleMap>
    </section>
  );
};
