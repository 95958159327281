import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Descriptions, Input, Card, Button, Space, Skeleton, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DropboxOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { 
    fetchPackages,
    insertPackage,
    updatePackage,
    deletePackage
} from '../../queries/packagesQueries';

import { PackagesIndicator } from './PackagesIndicator';

import { getColumnSearchPropsText } from '../../../../../../completed-routes/ui/screens/UseCases';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

import localStorageService from '../../../../../../../services/localStorageService';
const companyId = localStorageService.get('companyId');

export const PackagesScreen = () => {
    const [getPackages, dataPackages] = useLazyQuery(fetchPackages, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [insPackage] = useMutation(insertPackage, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [delPackage] = useMutation(deletePackage, { fetchPolicy: 'no-cache' });
    const [updPackage] = useMutation(updatePackage, { fetchPolicy: 'no-cache' });

    const [packages, setPackages] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getPackages();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPackages([]);

        if(!dataPackages.loading && dataPackages.data && dataPackages.data.packages.length > 0) {
            dataPackages.data.packages.map((item) => {
                const deleteable = dataPackages.data.packages_aggregate.aggregate.count === 0;
                const { id, name, height, width, depth, safetyFactor, defaultWeight, maxWeight, maxStackingWeight, principal, defaultItemName, defaultItemNumber, cubicMeters } = item;
                
                return setPackages((current) => [...current, {
                    id,
                    name,
                    height,
                    width,
                    depth,
                    safetyFactor,
                    defaultWeight,
                    maxWeight,
                    maxStackingWeight,
                    principal,
                    defaultItemName,
                    defaultItemNumber,
                    cubicMeters,
                    deleteable
                }]);
            });
        }
    }, [dataPackages.loading, dataPackages.data])

    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'name',
            ...getColumnSearchPropsText("Nombre", "name")
        },
        {
            title: 'Alto (cm)',
            key: 'height',
            dataIndex: 'height',
            ...getColumnSearchPropsText("Alto (cm)", "height")
        },
        {
            title: 'Ancho (cm)',
            key: 'width',
            dataIndex: 'width',
            ...getColumnSearchPropsText("Ancho (cm)", "width")
        },
        {
            title: 'Profundo (cm)',
            key: 'depth',
            dataIndex: 'depth',
            ...getColumnSearchPropsText("Profundo (cm)", "depth")
        },
        {
            title: 'Metros cúbicos',
            key: 'cubicMeters',
            dataIndex: 'cubicMeters',
            ...getColumnSearchPropsText("Metros cúbicos", "cubicMeters")
        },
        {
            title: 'Factor de seguridad',
            key: 'safetyFactor',
            dataIndex: 'safetyFactor',
            ...getColumnSearchPropsText("Factor de seguridad", "safetyFactor")
        },
        {
            title: 'Peso por defecto',
            key: 'defaultWeight',
            dataIndex: 'defaultWeight',
            ...getColumnSearchPropsText("Peso por defecto", "defaultWeight")
        },
        {
            title: 'Peso máximo de apilamiento',
            key: 'maxStackingWeight',
            dataIndex: 'maxStackingWeight',
            ...getColumnSearchPropsText("Peso máximo de apilamiento", "maxStackingWeight")
        },
        {
            title: 'Principal',
            key: 'principal',
            dataIndex: 'principal',
            align: 'center',
            ...getColumnSearchPropsText("Principal", "principal"),
            render: (value) => <Switch checked={value} disabled size='small' />
        },
        {
            title: 'Nombre por defecto',
            key: 'defaultItemName',
            dataIndex: 'defaultItemName',
            ...getColumnSearchPropsText("Nombre por defecto", "defaultItemName")
        },
        {
            title: 'Numero predeterminado',
            key: 'defaultItemNumber',
            dataIndex: 'defaultItemNumber',
            ...getColumnSearchPropsText("Numero predeterminado", "defaultItemNumber")
        }
    ];

    const handleSave = async (values) => {
        const { id, name, height, width, depth, safetyFactor, defaultWeight, maxWeight, maxStackingWeight, principal, defaultItemName, defaultItemNumber, cubicMeters, deleteable } = values;

        try {
            if(!isEditing) {
                const res = await insPackage({
                    variables: {
                        name,
                        height,
                        width,
                        depth,
                        safetyFactor,
                        defaultWeight,
                        maxWeight,
                        maxStackingWeight,
                        principal,
                        defaultItemName,
                        defaultItemNumber,
                        cubicMeters
                    },
                });
        
                if(res.data.insert_packages.affected_rows > 0) {
                    const { id } = res.data.insert_packages.returning[0];
    
                    setPackages((current) => [...current, {
                        id,
                        name,
                        height,
                        width,
                        depth,
                        safetyFactor,
                        defaultWeight,
                        maxWeight,
                        maxStackingWeight,
                        principal,
                        defaultItemName,
                        defaultItemNumber,
                        cubicMeters,
                        deleteable: true
                    }]);
                }
            } else {
                const res = await updPackage({
                    variables: {
                        id,
                        name,
                        height,
                        width,
                        depth,
                        safetyFactor,
                        defaultWeight,
                        maxWeight,
                        maxStackingWeight,
                        principal,
                        defaultItemName,
                        defaultItemNumber,
                        cubicMeters
                    }
                });

                if(res.data.update_packages.affected_rows > 0) {
                    const records = packages;
                    const indexToReplace = packages.findIndex((item) => item.id === id);

                    records.splice(indexToReplace, 1, {
                        id,
                        name,
                        height,
                        width,
                        depth,
                        safetyFactor,
                        defaultWeight,
                        maxWeight,
                        maxStackingWeight,
                        principal,
                        defaultItemName,
                        defaultItemNumber,
                        cubicMeters,
                        deleteable
                    });
                    setPackages([...records]);
                }
            }
        } catch (error) {
            console.error(error);
        }

        setIsEditing(false);

        form.setFieldsValue({
            id: '',
            name: '',
            height: 0,
            width: 0,
            depth: '',
            safetyFactor: 0,
            defaultWeight: 0,
            maxWeight: 0,
            maxStackingWeight: 0,
            principal: false,
            defaultItemName: '',
            defaultItemNumber: '',
            cubicMeters: '',
            deleteable: true
        });
    };

    const handleDelete = (record) => {
        const  id  = record;

        Swal.fire({
            title: '¿Seguro desea eliminar?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No, cancelar',
        }).then( async (result) => {
            if(result.isConfirmed) {
                try {
                    const res = await delPackage({
                        variables: {
                            id
                        },
                        fetchPolicy: 'no-cache'
                    });
        
                    if(res.data.delete_packages.affected_rows > 0) {
                        setPackages(packages.filter((item) => item.id !== id));
                        setClickedRow("");
                        Swal.fire({
                            title: 'Registro eliminado',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        setIsEditing(false);

                        form.setFieldsValue({
                            id: '',
                            name: '',
                            height: 0,
                            width: 0,
                            depth: '',
                            safetyFactor: 0,
                            defaultWeight: 0,
                            maxWeight: 0,
                            maxStackingWeight: 0,
                            principal: false,
                            defaultItemName: '',
                            defaultItemNumber: '',
                            cubicMeters: '',
                            deleteable: true
                        });
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    };

    const handleEdit = (record) => {
        const { id, name, height, width, depth, safetyFactor, defaultWeight, maxWeight, maxStackingWeight, principal, defaultItemName, defaultItemNumber, cubicMeters, deleteable } = record;
        
        setIsEditing(true);

        form.setFieldsValue({
            id,
            name,
            height,
            width,
            depth,
            safetyFactor,
            defaultWeight,
            maxWeight,
            maxStackingWeight,
            principal,
            defaultItemName,
            defaultItemNumber,
            cubicMeters,
            deleteable
        });
    };

    const handleClear = () => {   
        setClickedRow("");                           
        setIsEditing(false);
        handleCancel();

        form.setFieldsValue({
            id: '',
            name: '',
            height: 0,
            width: 0,
            depth: '',
            safetyFactor: 0,
            defaultWeight: 0,
            maxWeight: 0,
            maxStackingWeight: 0,
            principal: false,
            defaultItemName: '',
            defaultItemNumber: '',
            cubicMeters: '',
            deleteable: true
        });
    };

    const getCubicMeters = async () => {
        const {depth, height, width} = form.getFieldsValue();
        
        const cubicMeters = (parseFloat(depth)/100) * (parseFloat(height)/100) * (parseFloat(width)/100);

        form.setFieldsValue({
            cubicMeters: isNaN(cubicMeters) ? 0 : (cubicMeters.toFixed(4)*1)
        });

    };
    const formItemLayout = {
        labelCol: {
            lg: { span: 9 }
        },
        wrapperCol: {
            lg: { span: 14 }
        },
    };   
    return (
        <Col className='children-inner-container-with-bg'>
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <PackagesIndicator value={packages.length} label={"Total Paquetes"}><DropboxOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></PackagesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <PackagesIndicator value={(packages.filter(item => !item.enabled)).length} label={"Paquetes Activos"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></PackagesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <PackagesIndicator value={(packages.filter(item => item.enabled)).length} label={"Paquetes Inactivos"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></PackagesIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow)} disabled={clickedRow.length === 0}>Eliminar</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataPackages.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={packages}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Paquete' : 'Editar Paquete'} width={620}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave(values);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        name: '',
                        height: 0,
                        width: 0,
                        depth: '',
                        safetyFactor: 0,
                        defaultWeight: 0,
                        maxWeight: 0,
                        maxStackingWeight: 0,
                        principal: false,
                        defaultItemName: '',
                        defaultItemNumber: '',
                        cubicMeters: '',
                        deleteable: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                 
                            <Form.Item
                            label='Nombre'
                            labelAlign='left'
                                name='name'
                                rules={[{ required: true, message: 'Por favor ingrese un nombre'}]}
                            >
                                <Input />
                            </Form.Item>
                   
                            <Form.Item
                            label='Alto (cm)'
                            labelAlign='left'
                                name='height'
                                rules={[{ required: true, message: 'Por favor ingrese un valor'}]}
                                onChange={(value, object) =>
                                    getCubicMeters()
                                }
                            >
                                <Input />
                            </Form.Item>
          
                            <Form.Item
                            label='Ancho (cm)'
                            labelAlign='left'
                                name='width'
                                rules={[{ required: true, message: 'Por favor ingrese un valor'}]}
                                onChange={(value, object) =>
                                    getCubicMeters()
                                }
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label='Profundo (cm)'
                            labelAlign='left'
                                name='depth'
                                rules={[{ required: true, message: 'Por favor ingrese un valor'}]}
                                onChange={(value, object) =>
                                    getCubicMeters()
                                }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            label='Metros cúbicos'
                            labelAlign='left'
                                name='cubicMeters'
                            >
                                <Input />
                            </Form.Item>
         
                            <Form.Item
                            label='Factor de seguridad'
                            labelAlign='left'
                                name='safetyFactor'
                            >
                                <Input />
                            </Form.Item>
      
                            <Form.Item
                            label='Peso predeterminado'
                            labelAlign='left'
                                name='defaultWeight'
                            >
                                <Input />
                            </Form.Item>
   
                            <Form.Item
                            label='Máximo peso'
                            labelAlign='left'
                                name='maxWeight'
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label='Máximo peso apilado'
                            labelAlign='left'
                                name='maxStackingWeight'
                            >
                                <Input />
                            </Form.Item>
    
                            <Form.Item label='Principal'  labelAlign='left' name='principal' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
        
                            <Form.Item
                            label='Nombre predeterminado'
                            labelAlign='left'
                                name='defaultItemName'
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            label='Número de ítem predeterminado'
                            labelAlign='left'
                                name='defaultItemNumber'
                            >
                                <Input />
                            </Form.Item>
      
                    <Form.Item>
                        <Space>
                            <Button 
                                type='primary' 
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={handleClear}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}
