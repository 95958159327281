import React, { useState, useEffect } from "react";
import { Image } from "antd";
import { useLazyQuery } from "@apollo/client";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, TextField, IconButton, InputAdornment } from '@mui/material';
import Search from "@mui/icons-material/Search";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RouteCardLoad from "./RouteCard";
import OrdersCard from "./OrdersCard";
import DetailsCard from "./DetailsCard"
import OrdersCardDetails from "./DetailsOrder";
import moment from "moment";
import "moment/locale/es";
import localStorageService from "../../../../services/localStorageService";
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DrawIcon from '@mui/icons-material/Draw';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CollectionsIcon from '@mui/icons-material/Collections';
import SpeedIcon from '@mui/icons-material/Speed';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { getColumnSearchProps, getColumnSearchPropsText } from "./UseCases";
import {
  fetchCompletedRoutes,
  dataRoutesForRouteBatch,
  fetchtelemetry,
  countRegisterRoute,
  countRegisterRouteNumber,
  CountFilterPlaniCompleteRoutes,
  FilterPlaniCompleteRoutes,
  FilterDetailsCompleteRoutes,
  NewFilterPlaniCompleteRoutes,
  fetchCompletedRoutesDetails,
  CountFilterCompleteRoutesDetails
} from "../../queries/routesBatch";
import { DeliveryRoute } from "./DeliveryRoute";
import "../styles/styles.css";
import * as geolib from "geolib";
import { ReactComponent as FilterIcon } from "../../../../images/filter_alt_off_FILL0_wght400_GRAD0_opsz24.svg";

export const CompletedRoutesScreenV2 = (props) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    JSON.parse(localStorageService.get("userDistributionsCenters"))
  );
  const [telemetryUserVehicle, setTelemetryUserVehicle] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [
    telemetryOrderTablestartDateTime,
    setTelemetryOrderTablestartDateTime,
  ] = useState("");
  const [telemetryOrderTableEndDateTime, setTelemetryOrderTableEndDateTime] =
    useState("");
  const [visibleTelemetry, setVisibleTelemetry] = useState(false);
  const [tableResponsive, setTableResponsive] = useState(false);
  const [searchFilterPlanificador, setSearchFilterPlanificador] = useState("");
  const [searchFilterConsecutivo, setSearchFilterConsecutivo] = useState("");
  const [searchFilterCarga, setSearchFilterCarga] = useState("");
  const [searchFilterOVTRN, setSearchFilterOVTRN] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [numeberPagination, setNumeberPagination] = useState(30);
  const [limitPagination, setlimitPagination] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([])
  const [orderNumerSelected, setOrderNumerSelected] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedPanel, setExpandedPanel] = useState('panel1');
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);

  const [getCompletedRoutes, dataCompletedRoutes] = useLazyQuery(
    countRegisterRoute,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },

      fetchPolicy: "no-cache",
    }
  );
  const [getCompletedRoutesNumber, dataCompletedRoutesNumber] = useLazyQuery(
    countRegisterRouteNumber,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },

      fetchPolicy: "no-cache",
    }
  );

  const [getRoutesForRouteBatch, dataroutesForRouteBatch] = useLazyQuery(
    dataRoutesForRouteBatch,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },
      fetchPolicy: "no-cache",
    }
  );

  const handleChangeCompot = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      // Si el panel actual se cierra, establecer el siguiente panel como abierto
      switch (panel) {
        case 'panel1':
          setExpandedPanel('panel2');
          break;
        case 'panel2':
          setExpandedPanel('panel1');
          break;
        // Añadir más casos según sea necesario
        default:
          setExpandedPanel(null);
          break;
      }
    } else {
      // Si el panel se abre, mantenerlo abierto
      setExpandedPanel(panel);
    }
  };

  const [getPagineRoute] = useLazyQuery(fetchCompletedRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getFilterDetailsCompleteRoutes] = useLazyQuery(FilterDetailsCompleteRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getFilterCompleteRoute] = useLazyQuery(NewFilterPlaniCompleteRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getCountCompleteRoute] = useLazyQuery(CountFilterPlaniCompleteRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getCountCompleteRouteDetails] = useLazyQuery(CountFilterCompleteRoutesDetails, {
    fetchPolicy: "no-cache",
  });
  const [getFuncCompletedRoutesDetails] = useLazyQuery(fetchCompletedRoutesDetails, {
    fetchPolicy: "no-cache",
  });
  const [getTelemetry, dataTelemetry] = useLazyQuery(fetchtelemetry, {
    fetchPolicy: "no-cache",
  });
  const [polylines, setPolylines] = useState("");
  const [routesBatch, setRoutesBatch] = useState([]);
  const [filterRoutesBatch, setFilterRoutesBatch] = useState([]);
  const [filterRoutesBatchDetails, setFilterRoutesBatchDetails] = useState([]);
  const [countFilterRouteComplete, setCountFilterRouteComplete] = useState(0);
  const [countFilterRouteCompleteDetails, setCountFilterRouteCompleteDetails] = useState(0);
  const [dataFilterRoutesBatch, setDataFilterRoutesBatch] = useState([]);
  const [details, setDetails] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);
  const [selectedOrderTable, setSelectedOrderTable] = useState([]);
  const [lines, setLines] = useState("");
  const [filter, setFilter] = useState("");
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [currentPageSecond, setCurrentPageSecond] = useState(1);
  const [pageSizeSecond, setPageSizeSecond] = useState(10);
  const [currentPageTree, setCurrentPageTree] = useState(1);
  const [filterdataFirst, setFilterdataFirst] = useState([]);
  const [filterdataFirstRoute, setFilterdataFirstRoute] = useState([]);
  const [pageSizeTree, setPageSizeTree] = useState(10);
  const [activeHeader, setActiveHeader] = useState(false);
  const [activeHeadeget, setActiveHeaderget] = useState(true);

  useEffect(() => {
    if (
      telemetryUserVehicle?.length > 0 &&
      telemetryOrderTablestartDateTime?.length > 0 &&
      telemetryOrderTableEndDateTime?.length > 0
    ) {
      const telemetryOrderTableStartDateTimeUTC = moment(
        moment.utc(telemetryOrderTablestartDateTime)
      )
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss+00:00");
      const telemetryOrderTableEndDateTimeUTC = moment(
        moment.utc(telemetryOrderTableEndDateTime)
      )
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss+00:00");

      getTelemetry({
        variables: {
          userVehicleId: telemetryUserVehicle,
          startDateTime: telemetryOrderTableStartDateTimeUTC,
          endDateTime: telemetryOrderTableEndDateTimeUTC,
        },
      });
    }
  }, [
    telemetryUserVehicle,
    telemetryOrderTablestartDateTime,
    telemetryOrderTableEndDateTime,
  ]);

  const onSelectRoute = (route) => {
    setSelectedRoute(route);
  };

  const onSelectDetails = (details) => {
    setSelectedDetails(details);
  };

  useEffect(() => {
    if (dataTelemetry.data?.telemetry?.length > 0) {
      const distanceSum = dataTelemetry.data?.telemetry.reduce(
        (acc, curr) => acc + curr.distance,
        0
      );
      setTotalDistance(distanceSum);

      const speedSum = dataTelemetry.data?.telemetry.reduce(
        (acc, curr) => acc + curr.speed,
        0
      );
      const speedAverage = speedSum / dataTelemetry.data?.telemetry.length;
      setAverageSpeed(Math.round(speedAverage));

      setVisibleTelemetry(true);
    } else {
      setVisibleTelemetry(false);
    }
  }, [dataTelemetry.data, dataTelemetry.loading]);

  useEffect(() => {
    const warehouses = JSON.parse(
      localStorageService.get("selectedWarehouses")
    )?.warehouses;
    setSelectedWarehouse(warehouses);
  }, [props.flag]);

  useEffect(() => {
    getCompletedRoutes();
    getRoutesForRouteBatch();
    getCompletedRoutesNumber();
  }, []);

  useEffect(() => {
    setFilter(
      localStorage.getItem("searchTarget") &&
        localStorage.getItem("searchTarget").length > 0
        ? localStorage.getItem("searchTarget")
        : ""
    );
    if (localStorage.getItem("searchTarget").length === 0) {
      setFilterdataFirstRoute([]);
      setDataFilterRoutesBatch([]);
      setTelemetryUserVehicle([]);
    }
  }, [props.flag]);

  //console.log(pagineRoute.data?.routes)
  useEffect(() => {
    if (
      dataroutesForRouteBatch &&
      dataroutesForRouteBatch.data &&
      dataroutesForRouteBatch.data?.routes.length > 0
    ) {
      setFilterdataFirst(dataroutesForRouteBatch.data?.routes);
    }
  }, [dataroutesForRouteBatch, dataroutesForRouteBatch.data]);

  useEffect(() => {
    completeRoutesPagination(numeberPagination, limitPagination);
  }, []);

  const completeRoutesPagination = async (limit, offset) => {
    setLoading(true);
    setLoadingData(true)
    const responseOrdeNumberEdit = await getPagineRoute({
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
        limit: limit,
        offset: offset * limit,
      },
    });

    if (
      responseOrdeNumberEdit &&
      responseOrdeNumberEdit.data.routesBatch.length > 0
    ) {
      const newRoutesBatch = responseOrdeNumberEdit.data.routesBatch.map(
        (item) => {
          const {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            licencePlate,
            user,
          } = item;

          return {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            driverName: user.displayName,
            planner: routes[0]?.createByUser?.displayName,
            licencePlate,
          };
        }
      );
      setRoutesBatch(newRoutesBatch);
      setLoading(false);
      setLoadingData(false);
      setFilterRoutesBatch();
      setFilterDetails();


    }
  };

  /*
    const headerColumns = [
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: "Creación",
        className: "table-column",
        align: "center",
        render: (value) => {
          let date = moment.utc(value);
          let localTime = moment(date).local().format("DD/MM/YYYY h:mm a");
  
          return <span>{localTime}</span>;
        },
        ...getColumnSearchProps("createdAt"),
      },
      {
        key: "planner",
        dataIndex: "planner",
        title: "Planificador",
        className: "table-column",
        align: "center",
        render: (value) => {
          return <span>{value}</span>;
        },
        ...getColumnSearchPropsText("Planificador", "displayName"),
      },
      {
        key: "licencePlate",
        dataIndex: "licencePlate",
        className: "table-column",
        title: "Placa",
        render: (value) => {
          return <span>{value}</span>;
        },
        ...getColumnSearchPropsText("Placa", "licencePlate"),
      },
      {
        key: "driverName",
        dataIndex: "driverName",
        className: "table-column",
        title: "Conductor",
        render: (value) => {
          return <span>{value}</span>;
        },
        ...getColumnSearchPropsText("Conductor", "driverName"),
      },
      {
        key: "startDateRoute",
        dataIndex: "startDateRoute",
        title: "Inicio de Ruta",
        className: "table-column",
        align: "center",
        render: (value) => {
          let date = moment.utc(value);
          let localTime = moment(date).format("DD/MM/YYYY h:mm a");
  
          return <span>{localTime}</span>;
        },
        ...getColumnSearchProps("startDateRoute"),
      },
      {
        key: "endDateRoute",
        dataIndex: "endDateRoute",
        title: "Fin de Ruta",
        className: "table-column",
        align: "center",
        render: (value) => {
          let date = moment.utc(value);
          let localTime = moment(date).format("DD/MM/YYYY h:mm a");
  
          return <span>{localTime}</span>;
        },
        ...getColumnSearchProps("endDateRoute"),
      },
    ];
    
  
    const detailsColumns = [
      {
        key: "orderNumber",
        dataIndex: "orderTable",
        className: "table-column",
        title: "Orden",
        render: (record) => {
          return <span>{record?.orderNumber}</span>;
        },
        ...getColumnSearchPropsText("Orden", "orderTable.orderNumber"),
      },
      {
        key: "type",
        dataIndex: "orderTable",
        title: "Tipo",
        className: "table-column",
        align: "center",
        render: (record) => {
          return (
            <span>
              {record?.type === "Envío" || record?.type === "delivery"
                ? "Envío"
                : record?.type === "pickup"
                ? "Recogida"
                : record?.type === "customService"
                ? "Servicio Personalizado"
                : record?.type === "warehousesTransfer"
                ? "Traslado entre Bodegas"
                : ""}
            </span>
          );
        },
      },
      {
        key: "custFullName",
        dataIndex: "orderTable",
        className: "table-column",
        title: "Cliente",
        render: (record) => {
          return <span>{record?.custFullName}</span>;
        },
      },
      {
        key: "status",
        dataIndex: "status",
        title: "Estado",
        className: "table-column",
        render: (value) => {
          let status = "";
          switch (value) {
            case "none":
              status = "Ninguno";
              break;
            case "start":
              status = "Ninguno";
              break;
            case "started":
              status = "En reparto";
              break;
            case "delivered":
              status = "Completamente Entregado";
              break;
            case "undelivered":
              status = "Devuelto";
              break;
            case "partial_delivered":
              status = "Con Novedad";
              break;
            case "rescheduled_delivery":
              status = "Entrega Reprogramada";
              break;
            default:
              status = "Ninguno";
              break;
          }
  
          return status;
        },
      },
      {
        key: "description",
        dataIndex: "description",
        title: "Motivo",
        className: "table-column",
        render: (_, record) => {
          return <span>{record?.reason?.description}</span>;
        },
      },
      {
        key: "consecutiveBurden",
        dataIndex: "orderTable",
        title: "Carga",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.consecutiveBurden}</span>;
        },
      },
      {
        key: "consecutiveBill",
        dataIndex: "orderTable",
        title: "Factura",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.consecutiveBill}</span>;
        },
      },
      {
        key: "consecutiveRemission",
        dataIndex: "orderTable",
        title: "Remisión",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.consecutiveRemission}</span>;
        },
      },
      {
        key: "consecutiveSaleOrder",
        dataIndex: "orderTable",
        title: "Orden/TRN",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.consecutiveSaleOrder}</span>;
        },
      },
      {
        key: "receivedPerson",
        dataIndex: "receivedPerson",
        title: "Nombre",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record}</span>;
        },
      },
      {
        key: "receivedDocument",
        dataIndex: "receivedDocument",
        title: "Documento",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record}</span>;
        },
      },
      {
        key: "notes",
        dataIndex: "orderTable",
        className: "table-column",
        title: "Notas",
        render: (record) => {
          return <span>{record?.notes}</span>;
        },
      },
      {
        key: "paymentMethod",
        dataIndex: "orderTable",
        title: "Metodo de Pago",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.paymentMethod}</span>;
        },
      },
      {
        key: "state",
        dataIndex: "orderTable",
        title: "Departamento",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.state}</span>;
        },
      },
      {
        key: "city",
        dataIndex: "orderTable",
        title: "Ciudad",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.city}</span>;
        },
      },
      {
        key: "address",
        dataIndex: "orderTable",
        title: "Dirección",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.address}</span>;
        },
      },
      {
        key: "distanceText",
        dataIndex: "distanceText",
        title: "Distancia",
        className: "table-column",
        align: "center",
      },
      {
        key: "durationText",
        dataIndex: "durationText",
        title: "Duración",
        className: "table-column",
      },
      {
        key: "expectedDeliveryStartTime",
        dataIndex: "orderTable",
        title: "Fecha inicio compromiso de entrega ",
        className: "table-column",
        align: "center",
        render: (record) => {
          let date = moment.utc(record?.expectedDeliveryStartTime);
          let localTime = moment(date).format("DD/MM/YYYY h:mm a");
  
          return (
            <span>{localTime !== "Fecha inválida" ? localTime : "N/D"}</span>
          );
        },
      },
      {
        key: "expectedDeliveryEndTime",
        dataIndex: "orderTable",
        title: "Fecha fin compromiso de entrega",
        className: "table-column",
        align: "center",
        render: (record) => {
          let date = moment.utc(record?.expectedDeliveryEndTime);
          let localTime = moment(date).format("DD/MM/YYYY h:mm a");
  
          return (
            <span>{localTime !== "Fecha inválida" ? localTime : "N/D"}</span>
          );
        },
      },
      {
        key: "startDateTime",
        dataIndex: "startDateTime",
        title: "Inicio",
        className: "table-column",
        align: "center",
        render: (value) => {
          let date = moment.utc(value);
          let localTime = moment(date).format("DD/MM/YYYY h:mm a");
  
          return <span>{localTime}</span>;
        },
      },
      {
        key: "endDateTime",
        dataIndex: "endDateTime",
        title: "Fin",
        className: "table-column",
        align: "center",
        render: (value) => {
          let date = moment.utc(value);
          let localTime = moment(date).format("DD/MM/YYYY h:mm a");
  
          return <span>{localTime}</span>;
        },
      },
      {
        key: "consecutiveShipping",
        dataIndex: "orderTable",
        title: "Envío",
        className: "table-column",
        align: "center",
        render: (record) => {
          return <span>{record?.consecutiveShipping}</span>;
        },
      },
    ];
  
    const linesColumns = [
      {
        key: "productNumber",
        dataIndex: "productNumber",
        title: "Código",
        className: "table-column",
        align: "center",
        className: "table-column",
      },
      {
        key: "productName",
        dataIndex: "productName",
        className: "table-column",
        title: "Nombre",
      },
      {
        key: "initialQuantity",
        dataIndex: "initialQuantity",
        title: "Cantidad",
        className: "table-column",
        align: "right",
      },
      {
        key: "summationQuantity",
        dataIndex: "summationQuantity",
        title: "Entregado",
        className: "table-column",
        align: "right",
      },
    ];
    */

  let detilsLength;

  const handleSelectRoute = (selectedRoute) => {
    //console.log("Ruta seleccionada:", selectedRoute);

    onSelectRoute(selectedRoute);
    setDetails(selectedRoute.routes);
    detilsLength = selectedRoute.routes.length;
    FilterRouteBathcDetails(selectedRoute?.routes);
    setExpandedPanel('panel1');
    setTelemetryUserVehicle(
      selectedRoute.routes[0]?.user?.userVehicle?.id

    );
    setActiveHeader(true);
    //console.log(selectedRoute.routes) // Este es el cambio para manejar la selección del Box en lugar del botón
  };
  /*
    const rowSelectionHeader = {
      
      onChange: (selectedRowKeys, selectedRows) => {
        setDetails(selectedRows[0].routes);
        setSearchFilterConsecutivo();
        detilsLength = selectedRows[0].routes.length;
        FilterRouteBathcDetails(selectedRows[0]?.routes);
        setTelemetryUserVehicle(
          selectedRows[0]?.routes[0]?.user?.userVehicle?.id
        );
        setActiveHeader(true);
        //console.log(selectedRows[0].routes)
      },
      
    };
  */
  let linesLength;
  
  const handleSelectDetails = (selectedDetails) => {
console.log(selectedDetails);
    const records = selectedDetails?.orderTable?.ordersLines?.map((item) => ({
        id: item.id,
        productNumber: item.productNumber,
        productName: item.productName,
        initialQuantity: item.initialQuantity,
        summationQuantity: item.summationQuantity,
    })) || [];
 
    // Solo actualizar si los datos cambian
    if (selectedDetails?.orderTable?.orderNumber !== orderNumerSelected) {
        setOrderNumerSelected(selectedDetails?.orderTable?.orderNumber);
    }

    if (JSON.stringify(records) !== JSON.stringify(lines)) {
        setLines(records);
    }

    if (selectedDetails !== selectedOrderTable) {
        setSelectedOrderTable(selectedDetails);
    }

    setPolylines(selectedDetails?.polylines || []);
    setTelemetryOrderTablestartDateTime(selectedDetails?.startDateTime);
    setTelemetryOrderTableEndDateTime(selectedDetails?.endDateTime);
    setTableResponsive(true);

    if (activeHeader === false || filter.length > 0) {
        FilterRouteBathc(
            selectedDetails?.batch,
            selectedDetails?.user?.userVehicle?.id
        );
    }
};



  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
  };

  const handleCloseViewer = () => {
    setSelectedImage(null);
  };

  const handleCLickZoomIn = () => {
    { setZoomLevel(zoomLevel + 10) }
  }
  const handleCLickZoomOut = () => {
    { setZoomLevel(zoomLevel - 10) }
  }

  const handlePageChange = (page, pageSize) => {
    // console.log(filterRoutesBatch)
    if (filterRoutesBatch?.length > 0) {
      handlsavePlanificador(searchFilterPlanificador, pageSize, page)
      setFilterRoutesBatch();
    } else if (routesBatch?.length > 0) {
      completeRoutesPagination(pageSize, page);
      setRoutesBatch();
    }
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handlePageChangeSecond = (page, pageSize) => {
    setCurrentPageSecond(page);
    setPageSizeSecond(pageSize);
  };
  const handlePageChangeTree = (page, pageSize) => {
    setCurrentPageTree(page);
    setPageSizeTree(pageSize);
  };

  let filteredDataRouteBatch;
  const FilterRouteBathc = (dataBatch, dataTelemetry) => {
    if (filter.length > 0) {
      filteredDataRouteBatch = routesBatch.filter(
        (item) => item.batch === dataBatch
      );
      setDataFilterRoutesBatch(filteredDataRouteBatch);
      setActiveHeaderget(false);
      setTelemetryUserVehicle(dataTelemetry);
    } else {
      setDataFilterRoutesBatch(routesBatch);
      setTelemetryUserVehicle(dataTelemetry);
    }
  };
  const FilterRouteBathcDetails = (dataRoute) => {
    let _dataRoute = [];

    for (let i = 0; i < dataRoute?.length; i++) {
      const data = dataRoute[i];

      for (let j = 0; j < routesBatch?.length; j++) {
        const batch = routesBatch[j];

        for (let k = 0; k < batch?.routes?.length; k++) {
          const route = batch.routes[k];

          if (route.id === data.id) {
            _dataRoute.push(route);
          }
        }
      }
    }

    //console.log(_dataRoute);
    setFilterdataFirstRoute(_dataRoute);
  };

  const calcularDistanciaRecorrida = () => {
    if (
      !dataTelemetry.data?.telemetry ||
      dataTelemetry.data.telemetry.length === 0
    ) {
      return 0; // Devuelve 0 si no hay suficientes datos
    }

    let totalDistance = 0;
    let lastData = dataTelemetry.data.telemetry[0];
    for (let i = 1; i < dataTelemetry.data.telemetry.length; i++) {
      const currentData = dataTelemetry.data.telemetry[i];
      const distance = geolib.getDistance(
        { latitude: lastData.latitude, longitude: lastData.longitude },
        { latitude: currentData.latitude, longitude: currentData.longitude }
      );
      if (distance > 10) {
        totalDistance += distance;
      }
      lastData = currentData;
    }
    return totalDistance;
  };

  const calcularVelocidadPromedio = () => {
    if (
      !dataTelemetry.data?.telemetry ||
      dataTelemetry.data.telemetry.length === 0
    ) {
      return 0; // Devuelve 0 si no hay suficientes datos
    }

    let totalSpeed = 0;
    let count = 0;
    let lastData = dataTelemetry.data.telemetry[0];
    for (let i = 1; i < dataTelemetry.data.telemetry.length; i++) {
      const currentData = dataTelemetry.data.telemetry[i];
      const distance = geolib.getDistance(
        { latitude: lastData.latitude, longitude: lastData.longitude },
        { latitude: currentData.latitude, longitude: currentData.longitude }
      );
      if (distance > 10) {
        const time = moment(currentData.timestamp).diff(
          moment(lastData.timestamp),
          "seconds"
        );
        if (time > 0) {
          const speed = (distance / time) * 3.6; // Convertir de m/s a km/h
          totalSpeed += speed;
          count++;
        }
      }
      lastData = currentData;
    }
    const averageSpeed = count > 0 ? totalSpeed / count : 0;
    return averageSpeed.toFixed(2);
  };

  const textFieldEventPlanificador = (ev) => {
    const inputValuePlanificador = ev.target.value;
    setSearchFilterPlanificador(inputValuePlanificador);
  };
  const textFieldEventConsecutivo = (ev) => {
    const inputValueConsecutivo = ev.target.value;
    setSearchFilterConsecutivo(inputValueConsecutivo);
  };
  const textFieldEventCarga = (ev) => {
    const inputValueCarga = ev.target.value;
    setSearchFilterCarga(inputValueCarga);
  };
  const textFieldEventOVTRN = (ev) => {
    const inputValueOVTRN = ev.target.value;
    setSearchFilterOVTRN(inputValueOVTRN);
    setExpandedPanel('panel1');
  };

  let countFilterCompleteRouteDetails;
  const funcCompletedRoutesDetails = async (search, limit, offset) => {
    setLoading(true);
    setLoadingData(true);
    const responseFilterCompleteRouteDetails = await getFuncCompletedRoutesDetails({
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
        batch: search.batch,
        limit: limit,
        offset: offset * limit,
      },
    });
    if (
      responseFilterCompleteRouteDetails &&
      responseFilterCompleteRouteDetails.data?.routesBatch?.length > 0
    ) {
      countFilterCompleteRouteDetails = await getCountCompleteRouteDetails({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          batch: search.batch
        },
      });
      setCountFilterRouteCompleteDetails(countFilterCompleteRouteDetails?.data?.routesBatch_aggregate?.aggregate?.count);
      const filterRoutesBatchDetails = responseFilterCompleteRouteDetails.data.routesBatch.map(
        (item) => {
          const {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            licencePlate,
            user,
          } = item;

          return {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            driverName: user.displayName,
            planner: routes[0]?.createByUser?.displayName,
            licencePlate,
          };
        }
      );
      setFilterRoutesBatchDetails(filterRoutesBatchDetails);
      setLoading(false);
      setLoadingData(false);
      setFilterRoutesBatch()
      setRoutesBatch();
    }
  };
  let countFilterCompleteRoute;
  const handlsavePlanificador = async (search, limit, offset) => {
    if (searchFilterPlanificador.length > 0) {
      const responseFilterCompleteRoute = await getFilterCompleteRoute({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          displayName: search,
          limit: limit,
          offset: offset * limit,
        },
      });

      if (
        responseFilterCompleteRoute &&
        responseFilterCompleteRoute.data?.routesBatch?.length > 0
      ) {
        const filterRoutesBatch = responseFilterCompleteRoute.data.routesBatch.map(
          (item) => {
            const {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              licencePlate,
              user,
            } = item;

            return {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              driverName: user.displayName,
              planner: routes[0]?.createByUser?.displayName,
              licencePlate,
            };
          }
        );
        setRoutesBatch(filterRoutesBatch);
        setFilterRoutesBatch(responseFilterCompleteRoute.data.routesBatch);
        setFilterDetails(responseFilterCompleteRoute.data?.routesBatch[0]?.routes);
        setDetails();
      }
      else {
        completeRoutesPagination(30, 0);
        setSelectedOrderTable();
        setTableResponsive(false);
        setVisibleTelemetry(false);
      }
    } else {
      completeRoutesPagination(30, 0);
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }
  };
  const handlsaveConsecutivo = async (search, limit, offset) => {
    if (search.length > 0) {
      const responseFilterDetailsCompleteRoutes = await getFilterDetailsCompleteRoutes({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          orderNumber: search,
          consecutiveBurden: "",
          consecutiveSaleOrder: "",
          limit: limit,
          offset: offset * limit,
        },
      });

      if (
        responseFilterDetailsCompleteRoutes &&
        responseFilterDetailsCompleteRoutes.data?.routesBatch?.length > 0
      ) {
        const newRoutesBatch = responseFilterDetailsCompleteRoutes.data.routesBatch.map(
          (item) => {
            const {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              licencePlate,
              user,
            } = item;

            return {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              driverName: user?.displayName,
              planner: routes[0]?.createByUser?.displayName,
              licencePlate,
            };
          }
        );
        setRoutesBatch(newRoutesBatch);
        setFilterRoutesBatch(responseFilterDetailsCompleteRoutes.data.routesBatch);
        setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes);
        setDetails();
      } else {
        completeRoutesPagination(30, 0);
        setSelectedOrderTable();
        setTableResponsive(false);
        setVisibleTelemetry(false);
      }
    } else {
      completeRoutesPagination(30, 0);
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }
  };
  const handlsaveCarga = async (search, limit, offset) => {
    if (searchFilterCarga.length > 0) {
      const responseFilterDetailsCompleteRoutes = await getFilterDetailsCompleteRoutes({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          orderNumber: "",
          consecutiveBurden: search,
          consecutiveSaleOrder: "",
          limit: limit,
          offset: offset * limit,
        },
      });
      if (
        responseFilterDetailsCompleteRoutes &&
        responseFilterDetailsCompleteRoutes.data?.routesBatch?.length > 0
      ) {
        const newRoutesBatch = responseFilterDetailsCompleteRoutes.data.routesBatch.map(
          (item) => {
            const {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              licencePlate,
              user,
            } = item;

            return {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              driverName: user?.displayName,
              planner: routes[0]?.createByUser?.displayName,
              licencePlate,
            };
          }
        );

        setRoutesBatch(newRoutesBatch);
        setFilterRoutesBatch(responseFilterDetailsCompleteRoutes.data.routesBatch);
        setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes);
        setDetails();
      }
    } else {
      completeRoutesPagination(30, 0)
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }
  };
  const handlsaveOVTRN = async (search, limit, offset) => {
    if (searchFilterOVTRN.length > 0) {
      const responseFilterDetailsCompleteRoutes = await getFilterDetailsCompleteRoutes({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          orderNumber: "",
          consecutiveBurden: "",
          consecutiveSaleOrder: search,
          limit: limit,
          offset: offset * limit,
        },
      });
      setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes)
      setDetails();
      if (
        responseFilterDetailsCompleteRoutes &&
        responseFilterDetailsCompleteRoutes.data?.routesBatch?.length > 0
      ) {
        const newRoutesBatch = responseFilterDetailsCompleteRoutes.data.routesBatch.map(
          (item) => {
            const {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              licencePlate,
              user,
            } = item;

            return {
              id,
              batch,
              createdAt,
              startDateRoute,
              endDateRoute,
              routes,
              driverName: user?.displayName,
              planner: routes[0]?.createByUser?.displayName,
              licencePlate,
            };
          }
        );

        setRoutesBatch(newRoutesBatch);
        setFilterRoutesBatch(responseFilterDetailsCompleteRoutes.data.routesBatch);
        setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes);
        setDetails();
      }
    } else {
      completeRoutesPagination(30, 0)
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }

  };
  const handleClearFilters = () => {
    setSearchFilterConsecutivo("")
    setSearchFilterCarga("")
    setSearchFilterOVTRN("")
    setSearchFilterPlanificador("")
  }

  useEffect(() => {
  }, [filterRoutesBatch, filterDetails]);

  return (

    <Box className="children-inner-container-with-bg-completedRoute" xs={4} sm={12} xl={12} style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh' }}>
      <Box className="box-container-filterboxes">
        <Grid container spacing={1} className="grid-container-filterboxes">
          <Grid item xs={11}>
            <div className="grid-child-filterboxes">
              <TextField
                onChange={textFieldEventPlanificador}
                size="small"
                label="Buscar Planificador"
                color="error"
                style={{ width: "100%" }}
                value={searchFilterPlanificador?.length > 0 ? searchFilterPlanificador : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        {loading ? (
                          <CircularProgress size={10} color="error" />
                        ) : (
                          <Search onClick={() => handlsavePlanificador(searchFilterPlanificador, 30, 0)} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={11}>
            <div className="grid-child-filterboxes">
              <TextField
                onChange={textFieldEventConsecutivo}
                size="small"
                label="Buscar Consecutivo UM"
                color="error"
                style={{ width: "100%" }}
                value={searchFilterConsecutivo}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        {loading ? (
                          <CircularProgress size={10} color="error" />
                        ) : (
                          <Search onClick={() => handlsaveConsecutivo(searchFilterConsecutivo, 30, 0)} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={11}>
            <div className="grid-child-filterboxes">
              <TextField
                onChange={textFieldEventCarga}
                size="small"
                label="Buscar Carga"
                color="error"
                style={{ width: "100%" }}
                value={searchFilterCarga}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        {loading ? (
                          <CircularProgress size={10} color="error" />
                        ) : (
                          <Search onClick={() => handlsaveCarga(searchFilterCarga, 30, 0)} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={11}>
            <div className="grid-child-filterboxes">
              <TextField
                onChange={textFieldEventOVTRN}
                size="small"
                label="Buscar OV/TRN"
                color="error"
                style={{ width: "100%" }}
                value={searchFilterOVTRN}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        {loading ? (
                          <CircularProgress size={10} color="error" />
                        ) : (
                          <Search onClick={() => handlsaveOVTRN(searchFilterOVTRN, 30, 0)} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={11}>
            <div className="button-filter-icon">
              <IconButton onClick={handleClearFilters} className="filter-icon">
                <FilterIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Box>
      {loadingData ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className="parent-container" xs={4} sm={16} xl={16} style={{ flex: '1', overflowY: 'auto' }}>
          <Grid container spacing={1} className="">
            <Grid item xs={3} className="children--container-target-with-bg-back">
              <Box >
                <div className="" >
                  <RouteCardLoad
                    routesBatch={routesBatch?.length > 0 ? routesBatch : filterRoutesBatch?.length > 0 ? filterRoutesBatch : filterRoutesBatchDetails}
                    onSelectRoute={handleSelectRoute}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <div
                className="children-inner-container-target-with-bg-order"
                onChange={handleChangeCompot('panel1')}
                style={{
                  maxWidth: '90vw',
                  width: '100%',
                  maxHeight: '50vh',
                  height: 'auto',
                }}
              >
                <div
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <p className="title-labels-screen">
                    <span>Lista de Ordenes</span>
                  </p>
                </div>
                <div>
                  <Box className="order-list">
                    <OrdersCard
                      dailsOrder={details?.length > 0 ? details : filterDetails}
                      onSelectDetails={handleSelectDetails}
                    />
                  </Box>
                </div>
              </div>
              <Box xs={4} sm={16} xl={16} style={{ marginTop: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box className='children-inner-container-target-with-bg-map'>
                          <DeliveryRoute
                            dataTelemetry={dataTelemetry}
                            visibleTelemetry={visibleTelemetry}
                            polylines={polylines}
                          />
                          {/* Verifica si hay datos válidos para mostrar la telemetría */}
                          {visibleTelemetry && dataTelemetry.data?.telemetry[0] === undefined !== "No hay datos de la ruta" && (
                            <div className="card-telemetry">
                              <Grid container spacing={0}>
                                <Grid item xs={12}>
                                  <span className="ord-titule">Velocidad promedio: </span>
                                  <span><SpeedIcon style={{ color: "#D10505", fontSize: '1em', marginRight: '0.2em' }} />{calcularVelocidadPromedio()} Km/h</span>
                                </Grid>
                                <Grid item xs={12}>
                                  <span className="ord-titule">Distancia recorrida: </span>
                                  <span> <TravelExploreIcon style={{ color: "#D10505", fontSize: '1em', marginRight: '0.2em' }} />{calcularDistanciaRecorrida()} Mts</span>
                                  <br />
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          xs={4} sm={16} xl={16}
                          className="children-inner-container-target-with-bg-order-details-cointainer"
                        >
                          <p className="title-labels-screen">Detalles de la orden</p>
                          <OrdersCardDetails
                            dailsOrder={selectedOrderTable}
                          />
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={12} xl={12} style={{ marginRight: "2px" }}>
                        <div className="children-inner-container-target-with-bg-products" onChange={handleChangeCompot('panel2')}>
                          <div aria-controls="panel2-content" id="panel2-header">
                            <p className="title-labels-screen">Listado de Productos</p>
                          </div>
                          <div>
                            <DetailsCard lines={lines} />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="children-evidence">
                          <div>
                            <p className="title-labels-screen">Evidencias</p>
                          </div>
                          <div>
                            <Stack
                              direction={{ xs: 'row', sm: 'row' }}
                              spacing={{ xs: 1, sm: 2, md: 4 }}
                            >
                              {selectedOrderTable?.orderTable?.evidences?.map((selectedOrder, index) => (
                                <Card key={`container-${index}`} style={{ minWidth: '110px', height: '95%' }}>
                                  <CardActionArea onClick={() => handleImageClick(selectedOrder?.evidenceURL)}>
                                    <div style={{ width: '100%', height: '95%' }}>
                                      <Image
                                        src={selectedOrder?.evidenceURL}
                                        alt={`Evidencia ${index + 1}`}
                                        style={{ width: '120px', height: '140px', objectFit: 'cover' }}
                                      />
                                    </div>
                                    <CardContent className="title-labels-screen-picture">
                                      <Typography component="div">
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                          {selectedOrder?.evidenceType === 'FIRMA' && (
                                            <DrawIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }} />
                                          )}
                                          {selectedOrder?.evidenceType === 'FOTO' && (
                                            <CameraAltIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }} />
                                          )}
                                          {selectedOrder?.evidenceType === 'GALERÍA' && (
                                            <CollectionsIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }} />
                                          )}
                                          {selectedOrder?.evidenceType}
                                        </span>
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              ))}
                            </Stack>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
};
