import React, { } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as FilterIcon } from "../../../../../../../images/filter_alt_off_FILL0_wght400_GRAD0_opsz24.svg";
import TruncatedTextWithTooltip from "./Tooltip";

const ShippingCard = ({
  shipping,
  onSelect,
  handleResetShipping,
  listShipping,
  setListShipping,
  cargaSeleccionada,
  id,
}) => {
  const isSelected = listShipping.some(
    (item) => item.ShipmentId === shipping.ShipmentId
  );

  const checkIcon = isSelected
    ? "check-icon-container check-icon-container-selected"
    : "check-icon-container";

  const toggleSelection = () => {
    if (isSelected) {
      // Deseleccionar y remover de listShipping
      setListShipping(
        listShipping.filter((item) => item.ShipmentId !== shipping.ShipmentId)
      );
    } else {
      // Seleccionar y agregar al principio de listShipping
      setListShipping([shipping, ...listShipping]);
      onSelect(shipping);
    }
  };

  const loadSelected =
    cargaSeleccionada && shipping.LoadId === cargaSeleccionada.LoadId;

  const cardStyle = loadSelected
    ? "shipping-card selected-carga"
    : "shipping-card";

  return (
    <div className={cardStyle} onClick={() => onSelect(shipping)} id={id}>
      <div className="shipping-id">
        <h4>Envío</h4>
        <div className={checkIcon} onClick={toggleSelection}>
          <CheckIcon className="check-icon" />
        </div>
      </div>
      <div className="shipping-id-id">
        <h2>{shipping.ShipmentId}</h2>
      </div>
      <div className="shipping-address">
        <h2>
          <TruncatedTextWithTooltip text={shipping.Street} maxLength={28} />
        </h2>
      </div>
      <div className="shipping-detalles">
        <div className="shipping-detalles-column">
          <h5>
            {shipping?.SalesName
              ? "Nombre Cliente"
              : shipping?.InvoiceList && shipping?.InvoiceList.length > 0
                ? "Factura"
                : "Ciudad"}
          </h5>
          <div>
            <TruncatedTextWithTooltip
              text={
                shipping?.SalesName ||
                (shipping?.InvoiceList && shipping?.InvoiceList.length > 0
                  ? shipping?.InvoiceList[0].InvoiceId
                  : shipping?.City)
              }
              maxLength={17} // ajusta el máximo de caracteres según necesites
            />
          </div>
        </div>
        <div className="shipping-detalles-column">
          <h5>Orden TRN</h5>
          <p>{shipping.OrderNum}</p>
        </div>
      </div>
    </div>
  );
};

const ShippingList = ({
  onShippingSelect,
  carga,
  handleResetShipping,
  listShipping,
  setListShipping,
  selectShippingId,
}) => {
  const enviosParaMostrar = carga.LoadLines.filter(
    (envioActual) =>
      !listShipping.some(
        (envioSeleccionado) =>
          envioSeleccionado.ShipmentId === envioActual.ShipmentId
      )
  ).concat(listShipping);

  const handleClearShippingList = () => {
    setListShipping([]);
  };

  return (
    <div className="shipping-list">
      <div className="shipping-list-header">
        <div className="shipping-list-title">
          <h2 className="titulo">Listado de Envíos</h2>
          <h4 className="titulo-shipping-list">{carga.LoadId}</h4>
        </div>
        <div className="button-filter-icon-shipping" onClick={handleClearShippingList}>
          <FilterIcon className="filter-icon-shipping" />
        </div>
      </div>
      <div className="shipping-cards-container">
        {enviosParaMostrar.map((shipping) => {
          return (
            <ShippingCard
              key={shipping.$id}
              shipping={shipping}
              onSelect={() => onShippingSelect(shipping)}
              isSelected={listShipping.some(
                (item) => item.ShipmentId === shipping.ShipmentId
              )}
              handleResetShipping={handleResetShipping}
              setListShipping={setListShipping}
              listShipping={listShipping}
              cargaSeleccionada={carga}
              id={`shipping-${shipping.ShipmentId}`}
            />
          );
        })}
        {/*shipments.map((shipping) => (
          <ShippingCard
            key={shipping.$id}
            shipping={shipping}
            onSelect={() => onShippingSelect(shipping)}
            isSelected={selectShippingId === shipping.ShipmentId}
            handleResetShipping={handleResetShipping}
            setListShipping={setListShipping}
            listShipping={listShipping}
          />
        ))*/}
      </div>
    </div>
  );
};

export default ShippingList;
