import React, { useEffect, useRef, useState } from 'react';
import { VehiclesScreen } from '../screens/vehicles/ui/screens/VehiclesScreen';

export default function VehicleConfiguration() {

    return (
        <div>
           <VehiclesScreen></VehiclesScreen>
        </div>
    );
}