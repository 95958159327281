import React from "react";
import { Grid, Avatar } from '@mui/material';
import moment from "moment";
import imageStatus from "../../../../images/logistic.png";

const OrdersCardDetails = ({ dailsOrder }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <div container spacing={1}>
                    <div>
                        <span className="drive-details-label">Cliente:</span><br />
                    </div>
                    <div style={{ display: "flex" }}>
                        <Avatar style={{ backgroundColor: "var(--primaryColor)", marginRight: "15px" }} size="large">
                            {dailsOrder?.orderTable?.custFullName?.charAt(0)}
                        </Avatar>
                        <span className="drive-details-value planificator">{dailsOrder?.orderTable?.custFullName}</span>
                    </div>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                    <span className="drive-details-label">Dirección:</span><br />
                    <span className="drive-details-value planificator">{dailsOrder?.orderTable?.address}</span>
                </div>
            </Grid>
            <Grid item xs={2.5}>
                <div>
                    <span className="drive-details-label">Fecha inicio:</span><br />
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryStartTime).format("DD/MM/YYYY")}
                    </span>
                    <br />
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryStartTime).format("HH:mm:ss")}
                    </span>
                </div>
            </Grid>
            <Grid item xs={2.5}>
                <div>
                    <span className="drive-details-label">Fecha Fin:</span><br />
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryEndTime).format("DD/MM/YYYY")}
                    </span>
                    <br />
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryEndTime).format("HH:mm:ss")}
                    </span>
                </div>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className="selected-status">
                    <img src={imageStatus} />
                </div>
            </Grid>

        </Grid>
    );
}

export default OrdersCardDetails;
